import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading, WidgetBuilderSelect } from 'components';

const StyledLoading = styled(Loading)`
  height: 6.2rem;
`;

const AlumniWidgetBuilderDegreeLevel = props => {
  const { configurations, degreeLevelsData = [], setConfigurations } = props;
  return (
    <StyledLoading hasText={false} loaded={degreeLevelsData.length > 0}>
      {degreeLevelsData.length > 0 && (
        <WidgetBuilderSelect
          configurations={configurations}
          id="degree-level"
          label="Degree Level"
          hasBoldLabel
          optionsList={[{ label: 'none', value: '' }, ...degreeLevelsData]}
          selectionLabel="selectedDegreeLevel"
          setConfigurations={setConfigurations}
          {...props}
        />
      )}
    </StyledLoading>
  );
};

AlumniWidgetBuilderDegreeLevel.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderDegreeLevel;

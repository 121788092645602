import { v4 as uuidv4 } from 'uuid';
import config from '../config';

const generateWidgetId = ({ widgetType }) => `${widgetType}-widget-${uuidv4().substring(0, 6)}`;

export const processHeadingText = ({
  headingText,
  isBatch,
  isProgramNameBold,
  selectedPrograms
}) => {
  if (!headingText || selectedPrograms.length === 0) {
    return false;
  }
  const programName = isProgramNameBold
    ? `#${selectedPrograms[0].name}#`
    : selectedPrograms[0].name;

  return isBatch ? headingText.split('{ProgramName}').join(programName) : headingText;
};

/* Alumni Widget Utils */

export const generateAlumniWidgetSrcUrl = ({ configurations, selectedPrograms }) => {
  const {
    graduationTimeframeEnd,
    graduationTimeframeStart,
    hasEmployedInField,
    hasSalary,
    hasSubsequentAttainment,
    headingText,
    isBatch,
    ...params
  } = configurations;

  params.programs =
    isBatch && !!selectedPrograms.length
      ? [selectedPrograms[0].code]
      : selectedPrograms.map(({ code }) => code);

  params.headingText = processHeadingText({
    headingText,
    isBatch,
    isProgramNameBold: false,
    selectedPrograms
  });

  /* combine overview booleans into `overview` */
  let overviewArray = [];
  if (hasSalary) {
    overviewArray.push('salary');
  }
  if (hasEmployedInField) {
    overviewArray.push('employedInField');
  }
  if (hasSubsequentAttainment) {
    overviewArray.push('subsequentAttainment');
  }
  params.overview = overviewArray.join(',');

  /* combine graduationTimeframeStart and graduationTimeframeEnd into `graduationTimeframe` */
  params.graduationTimeframe = [graduationTimeframeStart, graduationTimeframeEnd].join(',');

  const parameters = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`);
  const widgetId = generateWidgetId({ widgetType: 'alumni' });
  const widgetUrl = `${config.widgetUrl}/alumni?${parameters.join('&')}&id=${widgetId}`;
  return widgetUrl;
};

export const generateAlumniEmbedCode = ({ clientId, configurations, selectedPrograms }) => {
  const previewWidgetUrl = generateAlumniWidgetSrcUrl({
    configurations,
    selectedPrograms
  });
  const widgetId = generateWidgetId({ widgetType: 'alumni' });
  return `<!-- Lightcast Alumni Widget -->
  <iframe onload="initializeScripts('${widgetId}', '${config.widgetUrl}')" id="${widgetId}" title="${clientId} alumni widget" style="border: 0; min-width: 100%; width: 1px" src="${previewWidgetUrl}"></iframe><script type="text/javascript" src="${config.widgetUrl}/scripts.js"></script>`;
};

/* Assessment Widget Utils */

export const generateAssessmentWidgetSrcUrl = ({ configurations }) => {
  const parameters = Object.keys(configurations).map(
    key => `${key}=${encodeURIComponent(configurations[key])}`
  );
  const widgetId = generateWidgetId({ widgetType: 'assessment' });
  const widgetUrl = `${config.widgetUrl}/assessment?${parameters.join('&')}&id=${widgetId}`;
  return widgetUrl;
};

export const generateAssessmentEmbedCode = ({ clientId, configurations }) => {
  const previewWidgetUrl = generateAssessmentWidgetSrcUrl({
    configurations
  });
  const widgetId = generateWidgetId({ widgetType: 'assessment' });
  return `<!-- Lightcast Assessment Widget -->
  <iframe onload="initializeScripts('${widgetId}', '${config.widgetUrl}')" id="${widgetId}" title="${clientId} assessment widget" style="border: 0; min-width: 100%; width: 1px" src="${previewWidgetUrl}"></iframe><script type="text/javascript" src="${config.widgetUrl}/scripts.js"></script>`;
};

/* Program Widget Utils */

export const generateProgramWidgetSrcUrl = ({ configurations, selectedPrograms, siteSettings }) => {
  const { orderCareersBy } = siteSettings;
  const {
    clientId,
    hasHeading,
    headingText,
    isBatch = false,
    locationName,
    primaryColor,
    stylesheetHref,
    visitButtonColor,
    ...rest
  } = configurations;
  const urlHeadingText = processHeadingText({
    headingText,
    isBatch,
    isProgramNameBold: true,
    selectedPrograms
  });
  const programs =
    isBatch && !!selectedPrograms.length
      ? [selectedPrograms[0].code]
      : selectedPrograms.map(({ code }) => code);
  const querystringConfigurations = {
    ...rest,
    clientId,
    locationName: encodeURIComponent(locationName),
    orderCareersBy,
    primaryColor: encodeURIComponent(primaryColor),
    programs,
    visitButtonColor: encodeURIComponent(visitButtonColor)
  };
  const parameters = Object.keys(querystringConfigurations).map(
    key => `${key}=${querystringConfigurations[key]}`
  );
  const widgetUrl = `${config.widgetUrl}?${parameters.join('&')}${
    hasHeading && urlHeadingText ? `&headingText=${encodeURIComponent(urlHeadingText)}` : ''
  }${!!stylesheetHref ? `&stylesheetHref=${stylesheetHref}` : ''}`;
  return widgetUrl;
};

export const generateStandardEmbedCode = ({ configurations, programs, siteSettings }) => {
  const { currentSite } = siteSettings;

  const previewWidgetUrl = generateProgramWidgetSrcUrl({
    configurations,
    currentSite,
    selectedPrograms: programs,
    siteSettings
  });
  const programNames = programs.map(({ name }) => name);

  const widgetId = generateWidgetId({ widgetType: 'program' });
  return `<!-- ${programNames} -->
  <iframe onload="initializeScripts('${widgetId}', '${config.widgetUrl}')" id="${widgetId}" title="${currentSite} widget" style="border: 0; min-width: 100%; width: 1px" src="${previewWidgetUrl}"></iframe><script type="text/javascript" src="${config.widgetUrl}/scripts.js"></script>`;
};

export const generateAlternativeProgramWidgetEmbedCode = ({
  configurations,
  programs,
  siteSettings
}) => {
  const {
    clientId,
    hasAllLocations,
    hasCareerList,
    hasEmployment,
    hasImages,
    hasJobOpenings,
    hasNationalData,
    hasOutlook,
    hasSkills,
    hasVisitButton,
    hasWages,
    isBatch,
    isHourlyWage,
    labelSet,
    locationName,
    primaryColor,
    stylesheetHref,
    visitButtonColor,
    headingText
  } = configurations;
  const { orderCareersBy } = siteSettings;
  const processedHeadingText = processHeadingText({
    headingText,
    isBatch,
    selectedPrograms: programs
  });

  const widgetId = generateWidgetId({ widgetType: 'program' });

  let programNames = [];
  let programCodes = [];
  for (let index = 0; index < programs.length; ++index) {
    const { code, name } = programs[index];
    programNames.push(name);
    programCodes.push(code);
  }

  return `<!-- ${programNames} -->
  <div id="${widgetId}-wrapper" style="height:100%" >
  <script type="text/javascript" src="https://widget.emsicc.com/scripts.js"></script>
  <script> generateWidget({clientId:'${clientId}',hasAllLocations:'${hasAllLocations}',hasCareerList:'${hasCareerList}',hasEmployment:'${hasEmployment}',hasImages:'${hasImages}',hasJobOpenings:'${hasJobOpenings}',hasNationalData:'${hasNationalData}',hasOutlook:'${hasOutlook}',hasSkills:'${hasSkills}',hasVisitButton:'${hasVisitButton}',hasWages:'${hasWages}',id:'${widgetId}',isHourlyWage:'${isHourlyWage}',labelSet:'${labelSet}',locationName:'${encodeURIComponent(
    locationName
  )}',orderCareersBy:'${orderCareersBy}',primaryColor:'${encodeURIComponent(
    primaryColor
  )}',programs:'${programCodes}', ${!!stylesheetHref ? `stylesheetHref:'${stylesheetHref}',` : ''}
  title:'${clientId} widget',visitButtonColor:'${encodeURIComponent(
    visitButtonColor
  )}',headingText:'${encodeURIComponent(processedHeadingText)}'})
  </script>
  </div>`;
};

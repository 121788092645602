import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import {
  keenAssessmentWidgetCompletions,
  keenAssessmentWidgetStarts
} from '../../../services/keen/queries';
import { fetchKeenData } from '../../../services';
import { PercentageChart } from 'components';

const { darkBlue, goldYellow, darkGreen, lightGray } = color;

export const Card = styled.div`
  width: 100%;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 4rem;
  padding: 4rem 2rem 0 0;
  border: 1px solid ${lightGray};
  border-radius: 3px;
`;

const AssessmentStartsCompletionsGraph = ({ nation, subdomain, timeframe }) => {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoaded(false);
      const startsData = await fetchKeenData({
        subdomain,
        timeframe,
        ...keenAssessmentWidgetStarts
      });

      const completionsData = await fetchKeenData({
        subdomain,
        timeframe,
        ...keenAssessmentWidgetCompletions
      });

      const newData = [
        {
          label: 'Started',
          color: darkGreen,
          results: startsData
        },
        {
          label: 'Completed',
          color: darkBlue,
          results: completionsData
        }
      ];
      setData(newData);
      setIsLoaded(true);
    };

    getData();
  }, [timeframe, subdomain]);

  return (
    <Card>
      <PercentageChart
        a11yHeader="Assessment Widget Starts and Completions"
        data={data}
        chartId="assessment-widget-completions"
        color={goldYellow}
        nation={nation}
        isLoaded={isLoaded}
        percentageLabel="Completed"
      />
    </Card>
  );
};

AssessmentStartsCompletionsGraph.propTypes = {
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.shape({
    end: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired
};

export default AssessmentStartsCompletionsGraph;

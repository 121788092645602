import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const HasWidgetBuilder = ({ children, isWidgetBuilderEnabled }) => {
  return isWidgetBuilderEnabled ? children : null;
};

const mapStateToProps = ({ sites }) => ({
  isWidgetBuilderEnabled: !!sites.currentSite
    ? sites.items[sites.currentSite].widgetBuilderSettings.alumniWidget.isEnabled ||
      sites.items[sites.currentSite].widgetBuilderSettings.assessmentWidget.isEnabled ||
      sites.items[sites.currentSite].widgetBuilderSettings.programWidget.isEnabled
    : false
});

HasWidgetBuilder.propTypes = {
  userRole: PropTypes.string
};

export default connect(mapStateToProps)(HasWidgetBuilder);

import { takeLatest } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import * as t from '../actions/constants';
import * as a from '../actions/actionCreators';
import {
  fetchProfiles as fetchProfilesAPI,
  fetchProfilesById,
  fetchAndDownloadResume as resumeAPI,
  logIntoCognito,
  logoutCognito,
  signUpWithCognito,
  updateSignUpProfile
} from '../services';
import { selectNation } from '../selectors/sites';
import { flashError } from '../helpers/flashmessage';

export function* login(action) {
  let user;
  try {
    user = yield call(logIntoCognito, action.email, action.password, action.ssoConnection);
    if (user) {
      const userData = {
        user_id: user.attributes['custom:auth0Id'] || user.attributes.sub,
        subdomain: user.attributes['custom:subdomain'],
        userRole: user.attributes['custom:userRole'],
        developer: user.attributes['custom:developer']
      };

      typeof pendo != 'undefined' &&
        pendo.updateOptions({
          visitor: {
            profileId: userData.user_id,
            role: userData.userRole
          },
          account: {
            subdomain: userData.subdomain
          }
        });

      if (userData.subdomain !== 'universal') {
        let profiles;
        profiles = yield call(fetchProfilesById, userData.user_id);
        const adminSubdomains = profiles
          .filter(({ userRole }) => userRole === 'admin')
          .map(({ subdomain }) => subdomain);
        userData.adminSubdomains = adminSubdomains;
      }
      yield put(a.loginSuccess(userData));
    }
  } catch (error) {
    yield put(a.loginFailure({ message: 'Incorrect Login Information' }));
  }
}

export function* logout() {
  logoutCognito();
}

export function* signUpSaga(action) {
  const { email, subdomain, profileId, password, firstName = '', lastName = '' } = action.payload;
  try {
    const user = yield call(signUpWithCognito, { email, password, subdomain });
    const data = {
      type: 'profile',
      id: user.username,
      attributes: {
        'profile-id': user.username,
        'user-role': 'admin',
        'family-name': lastName,
        'given-name': firstName,
        'is-invited': false
      }
    };
    yield call(updateSignUpProfile, subdomain, profileId, data);
    const userData = {
      subdomain,
      userRole: 'admin',
      user_id: user.username
    };
    yield put(a.loginSuccess(userData));
  } catch (error) {
    if (error.code === 'InvalidPasswordException') {
      flashError('Provided password cannot be used for security reasons.');
    } else {
      flashError('Sorry, there was an unexpected error updating account profile.');
    }
  }
}

export function* fetchProfiles(action) {
  const nation = yield select(selectNation);
  try {
    const profiles = yield fetchProfilesAPI(action.payload, nation);
    yield put(a.fetchProfilesSuccess(profiles));
  } catch (e) {
    yield put({ type: t.FETCH_PROFILES_FAILURE, error: e });
  }
}

export function* downloadResume(action) {
  yield resumeAPI(action.payload);
}

export function* watchLogin() {
  yield call(takeLatest, t.USER_LOGIN, login);
}

export function* watchLogout() {
  yield call(takeLatest, t.USER_LOGOUT, logout);
}

export function* watchSignUp() {
  yield call(takeLatest, t.USER_SIGNUP, signUpSaga);
}

export function* watchFetchProfiles() {
  yield call(takeLatest, t.FETCH_PROFILES_REQUEST, fetchProfiles);
}

export function* watchDownloadResume() {
  yield call(takeLatest, t.DOWNLOAD_RESUME, downloadResume);
}

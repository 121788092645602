import styled from 'styled-components';

export const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const HiddenA11yTable = styled.table`
  position: absolute;
  opacity: 0;
`;

export const Tooltip = styled.p`
  position: absolute;
  ${({ mousePosition }) => `left: ${mousePosition.x - 74}px; top: ${mousePosition.y - 77}px;`}
  width: 16rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
`;

export const TooltipTail = styled.span`
  width: 12px;
  height: 10px;
  overflow: hidden;
  position: absolute;
  bottom: -10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

:after {
  content: '';
  background: white;
  width: 142%;
  height: 142%;
  position: absolute;
  transform: rotate(45deg) translate(-71%,0);
`;

export const TooltipValue = styled.span`
  font-weight: 600;
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import { AnalyticsMiniChart, Row } from 'components';
import { AssessmentBySchoolStatCard } from 'containers';
import { keenAssessmentStarts, keenAssessmentCompletions } from '../../../services/keen/queries';

const { darkBlue, darkGreen } = color;

const StyledRow = styled(Row)`
  justify-content: flex-start;
  gap: 2rem;
  margin: 0 0 2rem 0;
`;

const Assessment = ({ hasSelectableProfileSchools }) => {
  return (
    <div>
      <StyledRow>
        <AnalyticsMiniChart
          dataLabel="interest assessments started"
          heading="Interest Assessments Started"
          color={darkGreen}
          keenQuery={keenAssessmentStarts}
        />

        <AnalyticsMiniChart
          dataLabel="interest assessments completed"
          heading="Interest Assessments Completed"
          color={darkBlue}
          keenQuery={keenAssessmentCompletions}
        />
      </StyledRow>
      {hasSelectableProfileSchools && (
        <Row>
          <AssessmentBySchoolStatCard />
        </Row>
      )}
    </div>
  );
};

Assessment.propTypes = {
  hasSelectableProfileSchools: PropTypes.bool
};

export default Assessment;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProfilesTableCard } from 'components';
import { modalShow, modalHide } from '../../reducers/modal';
import { selectSubdomain } from '../../selectors/subdomain';

class ProfilesTableCardContainer extends React.Component {
  static propTypes = {
    fetchProfiles: PropTypes.func,
    resumeEnabled: PropTypes.bool,
    requiresResumeApproval: PropTypes.bool,
    subdomain: PropTypes.string
  };

  state = { resumes: [] };

  render() {
    return <ProfilesTableCard {...this.props} />;
  }
}

export const mapStateToProps = ({ modal, profile, sites }) => {
  const currentSite = selectSubdomain(sites, profile);
  return {
    isDeveloper: profile.isDeveloper,
    isInterestsEnabled: sites.items[currentSite].assessmentLengths?.length > 0,
    isSkillsMatchEnabled: sites.items[currentSite].skillsMatchSettings?.hasSkillsAssessment,
    isStudentIdEnabled: sites.items[currentSite].studentIdEnabled,
    isSuperAdmin: profile.isSuperAdmin,
    modals: modal,
    nation: sites.items[currentSite].nation,
    resumeEnabled: sites.items[currentSite].resumeBuilder,
    requiresResumeApproval: sites.items[currentSite].requiresResumeApproval,
    subdomain: currentSite
  };
};

export default connect(mapStateToProps, {
  modalShow,
  modalHide
})(ProfilesTableCardContainer);

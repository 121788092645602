import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { locale } from '../../../helpers/locale';
import { color } from '../../../styles';
import {
  AssessmentDownloadButton,
  Button,
  LocalAdminConfirmationModal,
  Margin,
  ProfileInfoModal,
  ProfileResumeModalButton,
  ProfilesTableSortButton,
  TextButton,
  Toggle,
  Truncate
} from 'components';
import { FavoritesModal } from 'containers';
import SkillsAssessmentDownloadButton from '../SkillsAssessmentDownloadButton';

const { red, lightGray } = color;

const Table = styled.table`
  width: 100%;
  margin-top: 5rem;
  ${({ isLoading }) => isLoading && 'opacity: .5;'}
`;

const HeaderCell = styled.th`
  font-weight: 700;
  text-align: left;
`;

const SortHeader = styled.div`
  display: flex;
`;

const Row = styled.tr`
  height: 4.5rem;
  border-top: 1px solid #f2f2f2;
  background: ${({ rowColor }) => rowColor};
`;

const Cell = styled.td`
  vertical-align: middle;
  margin-right: 2rem;
`;

const CellContentsWrapper = styled.div`
  width: 15rem;
`;

const StyledToggle = styled(Toggle)`
  margin-top: -1rem;
  position: relative;
  top: 0.3rem;
  :hover > svg {
    color: ${({ checked, isDisabled }) => isDisabled && !checked && '#000 !important'};
  }
  > svg {
    ${({ isDisabled }) =>
      isDisabled &&
      `
      opacity: 0.5 !important;
      cursor: not-allowed;
    `};
  }
`;

const SmallButton = styled(Button)`
  position: relative;
  margin-left: 0.7rem;
  padding: 0.3rem 1rem !important;
  border-radius: 5px;
  letter-spacing: 0.15rem;
  font-size: 1.25rem;
  font-variant: small-caps;
  font-weight: 600;
`;

const ProfilesTable = ({
  data,
  getTableProfiles,
  isDeveloper,
  isInterestsEnabled,
  isLoading,
  isSkillsMatchEnabled,
  isStudentIdEnabled,
  isSuperAdmin,
  modalHide,
  modals,
  modalShow,
  nation,
  requiresResumeApproval,
  resumeEnabled,
  resumes,
  subdomain,
  ...sortProps
}) => {
  const formatValue = field => (field ? field : '---');
  const formattedProfileData = data.map(profile => {
    return {
      ...profile,
      currentSchool: formatValue(profile.currentSchool && profile.currentSchool.displayName),
      name: `${formatValue(profile.givenName)} ${formatValue(profile.familyName)}`.trim()
    };
  });

  return (
    <Table isLoading={isLoading}>
      <thead>
        <tr>
          <HeaderCell>Admin</HeaderCell>
          {nation === 'us' && (
            <HeaderCell>
              <SortHeader>
                Name
                <ProfilesTableSortButton sortField="name" columnHeader="name" {...sortProps} />
              </SortHeader>
            </HeaderCell>
          )}
          {nation !== 'us' && (
            <HeaderCell>
              <SortHeader>
                Email
                <ProfilesTableSortButton
                  sortField="email"
                  columnHeader="email"
                  isLoading={isLoading}
                  {...sortProps}
                />
              </SortHeader>
            </HeaderCell>
          )}
          {nation === 'us' && (
            <HeaderCell>
              <SortHeader>
                Current School
                <ProfilesTableSortButton
                  sortField="currentSchoolName"
                  columnHeader="current school"
                  isLoading={isLoading}
                  {...sortProps}
                />
              </SortHeader>
            </HeaderCell>
          )}
          <HeaderCell>
            <SortHeader>
              Sign Up
              <ProfilesTableSortButton
                sortField="createdAt"
                columnHeader="date of sign up"
                isLoading={isLoading}
                {...sortProps}
              />
            </SortHeader>
          </HeaderCell>
          <HeaderCell>
            <SortHeader>
              Last Visited
              <ProfilesTableSortButton
                sortField="lastLogin"
                columnHeader="date of last login"
                isLoading={isLoading}
                {...sortProps}
              />
            </SortHeader>
          </HeaderCell>
          {isInterestsEnabled && <HeaderCell>Interests</HeaderCell>}
          {isSkillsMatchEnabled && <HeaderCell>Skills</HeaderCell>}
          {resumeEnabled && nation === 'us' && (
            <HeaderCell>{`${locale.resume[nation]}s`}</HeaderCell>
          )}
          <HeaderCell>{`${locale.spellings.favorite[nation]}s`}</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {formattedProfileData.map((profile, index) => {
          return (
            <Row
              key={`profiles-table-row-${index}`}
              rowColor={index % 2 === 0 ? lightGray : 'white'}
            >
              <Cell>
                <Fragment>
                  <StyledToggle
                    type="checkbox"
                    ariaLabel={`Make ${profile.name} admin`}
                    checked={profile.userRole === 'admin'}
                    onChange={() => isSuperAdmin && modalShow(`admin${profile.profileId}`)}
                    isDisabled={!isSuperAdmin}
                    tabIndex={isSuperAdmin ? '0' : '-1'}
                  />
                  {modals[`admin${profile.profileId}`] && (
                    <LocalAdminConfirmationModal
                      row={profile}
                      cell={profile.userRole}
                      modalHide={modalHide}
                      nation={nation}
                      red={red}
                      subdomain={subdomain}
                      reloadProfiles={() => getTableProfiles({})}
                    />
                  )}
                </Fragment>
              </Cell>
              {nation === 'us' && (
                <Cell>
                  <CellContentsWrapper>
                    <TextButton
                      aria-label={`More info about ${profile.name}`}
                      onClick={() => modalShow(`profile${profile.profileId}`)}
                    >
                      {profile.name}
                    </TextButton>
                    {modals[`profile${profile.profileId}`] && (
                      <ProfileInfoModal
                        isDeveloper={isDeveloper}
                        isSuperUser={isSuperAdmin}
                        modalHide={modalHide}
                        name={`profile${profile.profileId}`}
                        nation={nation}
                        title={`${formatValue(profile.givenName)} ${formatValue(
                          profile.familyName
                        )}`.trim()}
                        profile={profile}
                        isStudentIdEnabled={isStudentIdEnabled}
                        reloadProfiles={() => getTableProfiles({})}
                      />
                    )}
                  </CellContentsWrapper>
                </Cell>
              )}
              {nation !== 'us' && (
                <Cell>
                  {isSuperAdmin && profile.userRole === 'admin' ? (
                    <CellContentsWrapper>
                      <TextButton
                        aria-label={`More info about ${profile.email}`}
                        onClick={() => modalShow(`profile${profile.profileId}`)}
                      >
                        {profile.email}
                      </TextButton>
                      {modals[`profile${profile.profileId}`] && (
                        <ProfileInfoModal
                          isSuperUser={isSuperAdmin}
                          modalHide={modalHide}
                          name={`profile${profile.profileId}`}
                          nation={nation}
                          title={profile.email}
                          profile={profile}
                          isStudentIdEnabled={isStudentIdEnabled}
                        />
                      )}
                    </CellContentsWrapper>
                  ) : (
                    <Truncate>{profile.email}</Truncate>
                  )}
                </Cell>
              )}
              {nation === 'us' && (
                <Cell>
                  <CellContentsWrapper>
                    <Truncate>{profile.currentSchool}</Truncate>
                  </CellContentsWrapper>
                </Cell>
              )}
              <Cell>
                {profile.createdAt ? moment(profile.createdAt).format(locale.date[nation]) : '---'}
              </Cell>
              <Cell>
                {profile.lastLogin ? moment(profile.lastLogin).format(locale.date[nation]) : '---'}
              </Cell>
              {isInterestsEnabled && (
                <Cell>
                  <Margin value="0 0 0 1.5rem">
                    <AssessmentDownloadButton
                      dataCy="interest-assessment-download-button"
                      id={profile.assessment && profile.profileId}
                      assessment={profile.assessment}
                      name={profile.name}
                      nation={nation}
                      subdomain={subdomain}
                    />
                  </Margin>
                </Cell>
              )}
              {isSkillsMatchEnabled && (
                <Cell>
                  <Margin value="0 0 0 1.25rem">
                    <SkillsAssessmentDownloadButton
                      dataCy="skills-assessment-download-button"
                      id={
                        !!profile.skillsAssessment &&
                        profile.skillsAssessment.attributes.selectedSkills.length > 0
                          ? profile.profileId
                          : ''
                      }
                      name={profile.name}
                      nation={nation}
                      skills={
                        !!profile.skillsAssessment
                          ? profile.skillsAssessment.attributes.selectedSkills
                          : []
                      }
                    />
                  </Margin>
                </Cell>
              )}
              {resumeEnabled && nation === 'us' && (
                <Cell>
                  <ProfileResumeModalButton
                    modals={modals}
                    modalShow={modalShow}
                    nation={nation}
                    profile={profile}
                    requiresResumeApproval={requiresResumeApproval}
                    resumes={resumes}
                  />
                </Cell>
              )}
              <Cell>
                {profile.favorites.careers.length || profile.favorites.programs.length ? (
                  <Fragment>
                    <SmallButton
                      aria-label={`view favorites for ${profile.name}`}
                      onClick={() => modalShow(`favorites${profile.profileId}`)}
                    >
                      view
                    </SmallButton>
                    {modals[`favorites${profile.profileId}`] && (
                      <FavoritesModal
                        modalName={`favorites${profile.profileId}`}
                        name={profile.name}
                        favorites={profile.favorites}
                      />
                    )}
                  </Fragment>
                ) : (
                  <Margin value="0 0 0 2.3rem">---</Margin>
                )}
              </Cell>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

ProfilesTable.propTypes = {
  data: PropTypes.array,
  getTableProfiles: PropTypes.func.isRequired,
  isDeveloper: PropTypes.bool,
  isInterestsEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isSkillsMatchEnabled: PropTypes.bool.isRequired,
  isStudentIdEnabled: PropTypes.bool,
  isSuperAdmin: PropTypes.bool.isRequired,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  requiresResumeApproval: PropTypes.bool,
  resumeEnabled: PropTypes.bool,
  resumes: PropTypes.array,
  subdomain: PropTypes.string
};

export default ProfilesTable;

import React from 'react';
import styled from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';

import theme from '../../../styles/theme';
import { locale } from '../../../helpers/locale';
import { withNationHOC } from '../../../hocs/withNation';
import { Authenticated, HasWidgetBuilder, LogoutLink } from 'containers';
import { NavPanel } from 'components';

const StyledNavPanel = styled(NavPanel)`
  line-height: 6rem;

  @media (max-width: 1060px) {
    display: none;
  }

  &:not(:last-child) {
    margin-right: 3rem;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${theme.menuColor};
`;

const GlobalNav = withNationHOC(({ nation }) => {
  return (
    <Authenticated>
      <StyledNavPanel data-test-selector="navigation" isTop>
        <HasWidgetBuilder>
          <StyledNavLink to="/widget-builder/">Widget Builder</StyledNavLink>
        </HasWidgetBuilder>
        <StyledNavLink to="/analytics/">Analytics</StyledNavLink>
        <StyledNavLink to="/programs/">{`${locale.edTrack[nation]} Information`}</StyledNavLink>
        <Authenticated requiresRole="super">
          <StyledNavLink to="/settings/">Settings</StyledNavLink>
        </Authenticated>
        <LogoutLink />
      </StyledNavPanel>
    </Authenticated>
  );
});

// withRouter forces component to update on route changes
export default withRouter(GlobalNav);

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { getDateFromISO } from '../../../helpers/get-date-from-iso';

import { drawChart } from './functions';
import { CanvasWrapper, HiddenA11yTable, Tooltip, TooltipTail, TooltipValue } from './components';

const LineChart = props => {
  const { a11yHeader, color, chartId, data, nation } = props;
  const canvasRef = useRef(null);
  const [mousePosition, setMousePosition] = useState();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState();

  useEffect(() => {
    drawChart({
      canvasRef,
      color,
      data,
      chartId,
      nation,
      setIsTooltipVisible,
      setMousePosition,
      setTooltipData
    });
  });

  useEffect(() => {
    addEventListener('resize', () => {
      drawChart({
        canvasRef,
        color,
        data,
        chartId,
        nation,
        setIsTooltipVisible,
        setMousePosition,
        setTooltipData
      });
    });
  });

  return (
    <CanvasWrapper id={`${chartId}-wrapper`}>
      <canvas ref={canvasRef} id={chartId} />
      {isTooltipVisible && (
        <Tooltip mousePosition={mousePosition}>
          <span>{tooltipData.date}</span>
          <TooltipValue>{`${tooltipData.value} ${tooltipData.label}`}</TooltipValue>
          <TooltipTail />
        </Tooltip>
      )}
      <HiddenA11yTable>
        <caption>{a11yHeader}</caption>
        <thead>
          <tr>
            <th />
            {data[0].results.map(({ timeframe }) => (
              <th key={`${chartId}-${timeframe.start}`}>
                {getDateFromISO(timeframe.start, nation)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map(({ label, results }) => (
            <tr key={`${chartId}-${label}`}>
              <td>{label}</td>
              {results.map(({ value }, index) => (
                <td key={`${chartId}-${index}`}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </HiddenA11yTable>
    </CanvasWrapper>
  );
};

LineChart.propTypes = {
  a11yHeader: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  chartId: PropTypes.string,
  data: PropTypes.array,
  nation: PropTypes.string.isRequired
};

export default LineChart;

import styled from 'styled-components';

export const Total = styled.span`
  display: flex;
  margin: 2rem 0.5rem 0 0;
  font-size: 4rem;
  font-weight: 600;
  color: ${({ color }) => color};
`;

export const TotalLabel = styled.span`
  height: 1.8rem;
  font-size: 1.5rem;
  white-space: nowrap;
`;

export const TotalRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem 2rem;
`;

export const TotalWrapper = styled.p`
  display: flex;
  align-items: flex-end;
  margin-right: 2rem;
`;

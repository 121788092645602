import React from 'react';
import PropTypes from 'prop-types';
import { fetchAssessmentDownload } from '../../../services';
import { DownloadButton } from 'components';
import { downloadPdf } from '../../../helpers/downloadPdf';

const AssessmentDownloadButton = ({ dataCy, assessment, id, name, nation, subdomain }) => {
  const userName = name === '--- ---' ? '' : name;
  const handleClick = async () => {
    const { results, questionSet } = assessment;
    const downloadResponse = await fetchAssessmentDownload({
      clientId: subdomain,
      nation,
      results,
      questionSet,
      userName: name
    });
    downloadPdf(downloadResponse, 'interest');
  };

  return (
    <DownloadButton
      download={() => handleClick()}
      dataCy={dataCy + '-' + userName.split(' ')[0]}
      id={id}
      name={userName}
      type="interest assessment results"
    />
  );
};

AssessmentDownloadButton.propTypes = {
  assessment: PropTypes.shape({
    questionSet: PropTypes.string,
    results: PropTypes.array
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  nation: PropTypes.string,
  subdomain: PropTypes.string.isRequired
};

export default AssessmentDownloadButton;

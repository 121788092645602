import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  keenSkillListStarts,
  keenSkillsAdded,
  keenResultsPageVisits
} from '../../../services/keen/queries';
import { withNationHOC } from '../../../hocs/withNation';

import { color } from '../../../styles';
import { AnalyticsMiniChart, SkillsByTypeCard } from 'components';

const { darkBlue, darkGreen, purple } = color;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const Skills = withNationHOC(() => {
  return (
    <Fragment>
      <Wrapper>
        <AnalyticsMiniChart
          dataLabel="views"
          heading="Skills List Started"
          color={darkGreen}
          keenQuery={keenSkillListStarts}
        />

        <AnalyticsMiniChart
          dataLabel="views"
          heading="Total Skills Selected"
          color={darkBlue}
          keenQuery={keenSkillsAdded}
        />

        <AnalyticsMiniChart
          dataLabel="views"
          heading="Results Page Visitors"
          color={purple}
          keenQuery={keenResultsPageVisits}
        />
      </Wrapper>
      <SkillsByTypeCard />
    </Fragment>
  );
});

export default Skills;

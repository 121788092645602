import { color as colors } from '../../../styles';
import { getDateFromISO } from '../../../helpers/get-date-from-iso';
import {
  drawBackground,
  drawCircle,
  drawChartAxisLines,
  drawLine,
  drawText,
  drawChartGradient
} from '../../../helpers/canvas-draw';

const { darkGray, mediumGray } = colors;

const sortDataSeries = data => [...data].sort((alpha, bravo) => bravo.value - alpha.value);

const findGreatestValue = data => {
  const greatestValues = [];

  for (let index = 0; index < data.length; index++) {
    const { results } = data[index];
    const newSortedDataSeries = sortDataSeries(results);
    const newGreatestValue = newSortedDataSeries[0].value;
    greatestValues.push(newGreatestValue);
  }

  const newGreatestValue = greatestValues.sort((alpha, bravo) => bravo - alpha)[0];

  return newGreatestValue;
};

const generateGreatestLineValue = ({ greatestValue, numberOfLines }) => {
  let roundingDenominator = 10;
  for (let index = 0; index < greatestValue.toString().length - 2; index++) {
    roundingDenominator = roundingDenominator * numberOfLines;
  }
  const greatestLineValue = Math.ceil(greatestValue / roundingDenominator) * roundingDenominator;
  return greatestLineValue;
};

const getDistanceBetween = (pointA, pointB) => {
  let y = pointB.x - pointA.x;
  let x = pointB.y - pointA.y;
  return Math.sqrt(x * x + y * y);
};

const drawAxisLines = ({
  context,
  lineColor,
  margin,
  numberOfLines,
  textOffset,
  verticalOffset
}) => {
  const offset = margin.x + textOffset;
  for (let index = 0; index < numberOfLines + 1; index++) {
    const lineHeight =
      index * ((context.canvas.height - verticalOffset - margin.y) / numberOfLines) +
      verticalOffset;
    if (index === numberOfLines) {
      drawChartAxisLines({
        context,
        color: lineColor,
        offset,
        lineHeight,
        lineWidth: 2,
        isDotted: false,
        inset: 0
      });
    } else {
      drawChartAxisLines({ context, color: lineColor, offset, lineHeight });
    }
  }
};

const drawVerticalLabels = ({
  labelColor,
  context,
  fontSize,
  margin,
  numberOfLines,
  textOffset,
  verticalIncrement,
  verticalOffset
}) => {
  const xPoint = margin.x + textOffset - 10;

  for (let index = 0; index < numberOfLines; index++) {
    const text = verticalIncrement * (numberOfLines - index);
    const yPoint =
      index * ((context.canvas.height - verticalOffset - margin.y) / numberOfLines) +
      verticalOffset +
      fontSize / 3;
    drawText({ context, color: labelColor, fontSize, text, xPoint, yPoint });
  }
};

const drawDataPoints = ({
  context,
  color,
  data,
  fontSize,
  greatestLineValue,
  labelColor,
  lineIndex,
  margin,
  pointRadius,
  spaceBetweenPoints,
  textOffset,
  verticalOffset
}) => {
  let points = [
    {
      x: context.canvas.width - margin.x - 5,
      y: context.canvas.height - margin.y + 2
    },
    {
      x: margin.x + textOffset,
      y: context.canvas.height - margin.y + 2
    }
  ];

  for (let index = 0; index < data.length; index++) {
    const currentValue = data[index].value;

    const actualGraphHeight = context.canvas.height - verticalOffset - margin.y;
    const scaleMultiplier = actualGraphHeight / greatestLineValue;
    const scaledCurrentValue = currentValue * scaleMultiplier;
    const xPoint = margin.x + textOffset + index * spaceBetweenPoints;
    const yPoint = context.canvas.height - scaledCurrentValue - margin.y;

    const nextdex = index + 1;
    if (!!data[nextdex]) {
      const nextValue = data[nextdex].value;
      const scaledNextValue = nextValue * scaleMultiplier;
      const xPointB = margin.x + textOffset + nextdex * spaceBetweenPoints;
      const yPointB = context.canvas.height - scaledNextValue - margin.y;
      drawLine({
        context,
        color,
        isDashed: lineIndex > 0,
        xPointA: xPoint,
        xPointB,
        yPointA: yPoint,
        yPointB
      });
    }

    points.push({ x: xPoint, y: yPoint });
    drawCircle({ context, color, radius: pointRadius, xPoint, yPoint });

    const date = new Date(data[index].timeframe.start);
    const newDay = date.toLocaleString('default', { month: 'short', day: '2-digit' });
    let dateLabelOffset = textOffset - fontSize;
    if (index === 0) {
      dateLabelOffset = textOffset + fontSize;
    } else if (index === data.length - 1) {
      dateLabelOffset = 0;
    }

    const dayDate = date.toLocaleString('default', { day: '2-digit' });
    const isDateToShow = (data.length < 18 && index === 0) || dayDate === '01' || dayDate === '15';

    if (data.length < 18 || isDateToShow) {
      drawText({
        context,
        color: labelColor,
        fontSize,
        text: newDay,
        xPoint: xPoint + dateLabelOffset,
        yPoint: actualGraphHeight + verticalOffset + 30
      });
    }
  }

  drawChartGradient({ context, color, margin, points });

  return points.splice(2, points.length);
};

const handleHover = ({
  canvas,
  data,
  dataPoints,
  labels,
  nation,
  setIsTooltipVisible,
  setMousePosition,
  setTooltipData
}) => {
  canvas.onmousemove = function(event) {
    let rect = this.getBoundingClientRect();
    const { clientX, clientY } = event;
    const mousePoint = { x: clientX - rect.left, y: clientY - rect.top };

    let isTooltipVisible = false;
    for (let index = 0; index < dataPoints.length; index++) {
      const currentDataPoint = dataPoints[index];
      const distanceBetween = getDistanceBetween(mousePoint, currentDataPoint);
      const threshold = 4;
      if (distanceBetween <= threshold) {
        isTooltipVisible = true;
        const dayLabel = getDateFromISO(data[index].timeframe.start, nation);
        const dataSectionSize = data.length / labels.length;
        const labelIndex = Math.floor(index / dataSectionSize);
        const label = labels[labelIndex];
        setTooltipData({ date: dayLabel, label, value: data[index].value });
        break;
      }
    }
    setMousePosition(mousePoint);
    setIsTooltipVisible(isTooltipVisible);
  };
};

export const drawChart = ({
  canvasRef,
  data,
  chartId,
  fontSize = 14,
  margin = {
    x: 10,
    y: 40
  },
  nation,
  numberOfLines = 5,
  pointRadius = 3,
  setIsTooltipVisible,
  setMousePosition,
  setTooltipData
}) => {
  const canvas = canvasRef.current;
  const context = canvas.getContext('2d');
  const canvasWrapper = document.getElementById(`${chartId}-wrapper`);
  const canvasWrapperWidth = canvasWrapper.getBoundingClientRect().width;
  canvas.width = canvasWrapperWidth;
  canvas.height = 180;

  const greatestValue = findGreatestValue(data);
  const greatestLineValue = generateGreatestLineValue({ greatestValue, numberOfLines });

  const props = {
    context,
    data,
    greatestValue,
    greatestLineValue,
    fontSize,
    labelColor: darkGray,
    lineColor: mediumGray,
    nation,
    margin,
    pointRadius,
    numberOfLines,
    textOffset: 35,
    verticalIncrement: greatestLineValue / numberOfLines,
    verticalOffset: fontSize * 1.5
  };

  drawBackground(props);

  const numberOfSpaces = data[0].results.length - 1;

  props.spaceBetweenPoints =
    (context.canvas.width - margin.x * 2 - props.textOffset - 5) / numberOfSpaces;

  drawAxisLines(props);
  drawVerticalLabels(props);

  let dataPoints = [];
  let combinedData = [];
  let labels = [];

  for (let index = 0; index < data.length; index++) {
    const { color, label, results } = data[index];
    const newDataPoints = drawDataPoints({ ...props, color, data: results, lineIndex: index });
    dataPoints = [...dataPoints, ...newDataPoints];
    combinedData = [...combinedData, ...results];
    labels.push(label);
  }

  handleHover({
    canvas,
    data: combinedData,
    dataPoints,
    labels,
    setIsTooltipVisible,
    setMousePosition,
    setTooltipData
  });
};

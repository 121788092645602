import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DownloadIcon from '../../../images/download-icon.svg';
import theme from '../../../styles/theme';

const StyledDownloadIcon = styled(DownloadIcon)`
  width: 18px;
  height: 15px;
  position: relative;
  top: 5px;
  fill: ${theme.linkColor};
`;

const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 3px;
`;

const Span = styled.span`
  margin-left: 2px;
`;

const DownloadButton = ({ dataCy, id, name, download, type, nation }) => {
  return id ? (
    <Button
      aria-label={`Download ${type} for ${name}`}
      onClick={() => download({ id, name, nation })}
      data-cy={dataCy}
    >
      <StyledDownloadIcon />
    </Button>
  ) : (
    <Span>---</Span>
  );
};

DownloadButton.propTypes = {
  // Type of file to be downloaded, e.g. 'assessment' or 'resume'
  type: PropTypes.string,
  // can be either Resume or Assessment ID.
  // If id is not defined, user does not have Resume or Assessment, and the download button should not be rendered.
  id: PropTypes.string,
  download: PropTypes.func,
  // Individual's name, used for aria-label
  name: PropTypes.string,
  nation: PropTypes.string
};

export default DownloadButton;

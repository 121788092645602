// secondary functions

const sortProgramsAlphabetically = ({
  currentSite,
  hasManyInstitutions,
  institutionsById,
  programs
}) =>
  programs.sort((alpha, bravo) => {
    const makeSortableString = ({ institutionId, slug }) =>
      `${slug}-${
        hasManyInstitutions ? institutionsById[`${currentSite}-${institutionId}`].displayName : ''
      }`;

    const alphaValue = makeSortableString(alpha);
    const bravoValue = makeSortableString(bravo);

    if (alphaValue < bravoValue) {
      return -1;
    }
    if (alphaValue > bravoValue) {
      return 1;
    }
    return 0;
  });

// exported functions

export const fetchProgramsForWidget = async ({
  currentSite,
  hasManyInstitutions,
  institutionsById,
  nation,
  setPrograms,
  setFilteredPrograms,
  setIsProgramsLoading
}) => {
  setIsProgramsLoading(true);
  const programs = await fetchProgramsBySubdomain({ subdomain: currentSite, nation });
  const sortedResult = sortProgramsAlphabetically({
    currentSite,
    hasManyInstitutions,
    institutionsById,
    programs
  });
  setPrograms(sortedResult);
  setFilteredPrograms(sortedResult);
  setIsProgramsLoading(false);
};

export const filterByKeyword = ({ keyword, programs, setFilteredPrograms }) => {
  if (keyword === '') {
    setFilteredPrograms(programs);
  } else {
    const newFilteredPrograms = programs.filter(({ credential, name }) => {
      const lowerCasedKeyword = keyword.toLowerCase();
      return (
        (name && name.toLowerCase().includes(lowerCasedKeyword)) ||
        (credential && credential.toLowerCase().includes(lowerCasedKeyword))
      );
    });
    setFilteredPrograms(newFilteredPrograms);
  }
};

export const handleSave = ({
  programs,
  selectedLocalProgramsByCode,
  setSelectedPrograms,
  toggleIsModalOpen
}) => {
  const newSelectedPrograms = Object.keys(selectedLocalProgramsByCode)
    .filter(code => selectedLocalProgramsByCode[code])
    .map(code => programs.find(program => program.code === code));
  setSelectedPrograms(newSelectedPrograms);
  toggleIsModalOpen();
};

export const selectAllPrograms = ({ filteredPrograms, setSelectedLocalProgramsByCode, value }) => {
  const newSelectedProgramsById = {};
  filteredPrograms.map(program => (newSelectedProgramsById[program.code] = value));
  setSelectedLocalProgramsByCode(newSelectedProgramsById);
};

import React from 'react';
import PropTypes from 'prop-types';
import { color } from '../../../styles';
import { Card, TotalChart } from 'components';

const { darkGray } = color;

const ProfilesChart = ({ data, isLoaded, nation }) => (
  <Card>
    <TotalChart
      a11yHeader="Profiles Created"
      data={data}
      chartId="profiles-created"
      color={darkGray}
      nation={nation}
      isLoaded={isLoaded}
    />
  </Card>
);

ProfilesChart.propTypes = {
  data: PropTypes.array,
  isLoaded: PropTypes.bool,
  nation: PropTypes.string.isRequired
};

export default ProfilesChart;

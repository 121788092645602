export const drawBackground = ({ context }) => {
  context.fillStyle = 'white';
  context.fillRect(0, 0, context.canvas.width, context.canvas.height);
};

export const drawCircle = ({ context, color, radius = 1, xPoint, yPoint }) => {
  context.beginPath();
  context.arc(xPoint, yPoint, radius, 0, 2 * Math.PI, false);
  context.fillStyle = color;
  context.fill();
  context.lineWidth = 0;
  context.stroke();
};

export const drawChartAxisLines = ({
  context,
  color,
  offset,
  lineHeight,
  lineWidth = 1,
  isDotted = true,
  inset = 0
}) => {
  if (isDotted) {
    context.setLineDash([3, 3]);
  }
  context.beginPath();
  context.moveTo(offset + inset, lineHeight);
  context.lineTo(context.canvas.width - 15 - inset, lineHeight);
  context.strokeStyle = color;
  context.lineWidth = lineWidth;
  context.stroke();
  if (isDotted) {
    context.setLineDash([]);
  }
};

export const drawLine = ({ context, color, isDashed, xPointA, xPointB, yPointA, yPointB }) => {
  if (isDashed) {
    context.setLineDash([5, 3]);
  }
  context.beginPath();
  context.moveTo(xPointA, yPointA);
  context.lineTo(xPointB, yPointB);
  context.strokeStyle = color;
  context.lineWidth = 3;
  context.stroke();
  context.setLineDash([]);
};

export const drawText = ({ context, color, fontSize, text, xPoint, yPoint }) => {
  context.font = `${fontSize}px sans-serif`;
  context.textAlign = 'right';
  context.fillStyle = color;
  context.fillText(text, xPoint, yPoint);
};

export const drawChartGradient = ({ context, color, margin, points }) => {
  context.beginPath();
  context.moveTo(0, 0);

  const verticalOffset = 1;
  for (let index = 0; index < points.length; index++) {
    const { x, y } = points[index];
    context.lineTo(x, y + verticalOffset);
  }

  context.lineTo(points[0].x, points[0].y + verticalOffset);
  context.closePath();

  // Create gradient
  const gradient = context.createLinearGradient(0, 0, 0, context.canvas.height - margin.y);
  gradient.addColorStop(0, color);
  gradient.addColorStop(1, 'white');

  // Fill with gradient
  gradient.fillStyle = gradient;

  context.fillStyle = gradient;
  context.globalAlpha = 0.4;
  context.fill();
  context.restore();
  context.globalAlpha = 1;
};

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';
import { getProfiles } from '../../../services/profiles';
import { fetchResumes } from '../../../services';

import {
  Card,
  Header,
  HideContentOnMobile,
  Loading,
  ProfilesTable,
  Paginate,
  SearchInput,
  SelectAutoComplete,
  Toggle
} from 'components';

const { green, red, yellow } = color;

const StyledCard = styled(Card)`
  min-height: 300px;
  padding-bottom: 0 !important;
`;

const SelectAutoCompleteMargin = styled.div`
  margin-right: 1.5rem;
  width: 20%;
`;

const SearchFilterWrapper = styled.div`
  display: flex;
`;

const FilterBubble = styled.span`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  position: absolute;
  padding: 5px;
  top: 10px;
  left: 10px;
  background-clip: padding-box;
`;

const Message = styled.div`
  text-align: center;
  margin: 6rem 2.5rem;
`;

const Error = styled.div`
  text-align: center;
  margin: 2.5rem;
  color: red;
`;

const ProfilesTableCard = ({ nation, requiresResumeApproval, subdomain, ...tableProps }) => {
  const [areProfilesLoaded, setAreProfilesLoaded] = useState(false);
  const [areResumesLoaded, setAreResumesLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultSortField, setDefaultSortField] = useState('createdAt');
  const [hasError, setHasError] = useState(false);
  const [isAdminFilterSelected, setIsAdminFilterSelected] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [keywordFilter, setKeywordFilter] = useState('');
  const [profileData, setProfileData] = useState([]);
  const [profilesCount, setProfilesCount] = useState(0);
  const [resumes, setResumes] = useState([]);
  const [skipBy, setSkipBy] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedResumeFilterOption, setSelectedResumeFilterOption] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState('-createdAt');

  const numberOfProfilesPerPage = 15;

  const resetPagination = async () => {
    setCurrentPage(1);
    setSkipBy(0);
  };

  const resumeFilterBubbles = {
    [`All ${locale.resume[nation]}s`]: <FilterBubble backgroundColor="transparent" />,
    'Awaiting Review': <FilterBubble backgroundColor={yellow} />,
    Approved: <FilterBubble backgroundColor={green} />,
    'Changes Requested': <FilterBubble backgroundColor={red} />
  };

  const resumeFilterToParam = {
    [`All ${locale.resume[nation]}s`]: null,
    'Awaiting Review': 'pending',
    Approved: 'approved',
    'Changes Requested': 'denied,edited'
  };

  const getTableProfiles = async ({
    search = keywordFilter,
    skip = skipBy,
    sortBy = selectedSortBy,
    resumeFilter = resumeFilterToParam[selectedResumeFilterOption],
    isAdmin = isAdminFilterSelected
  }) => {
    setAreProfilesLoaded(false);

    const profilesResponse = await getProfiles(
      subdomain,
      `?limit=${numberOfProfilesPerPage}
&skip=${skip}
&sort=${sortBy}
${!!search ? `&search=${encodeURIComponent(search.replace(' ', ','))}` : ''}
${isAdmin ? '&isAdmin=true' : ''}
${!!resumeFilter ? `&resumeApprovalStatus=${resumeFilter}` : ''}`
    );

    if (!profilesResponse) {
      setHasError(true);
    } else {
      const { profiles, total } = profilesResponse;
      setProfileData(profiles);
      setProfilesCount(total);
      setAreProfilesLoaded(true);
      setIsFirstLoad(false);
    }
  };

  const getSubdomainResumes = async () => {
    setAreResumesLoaded(false);
    const resumeData = await fetchResumes(subdomain);
    if (!resumeData) {
      setHasError(true);
    } else {
      setResumes(Object.values(resumeData));
      setAreResumesLoaded(true);
    }
  };

  useEffect(() => {
    if (profileData.length === 0) {
      setAreProfilesLoaded(false);
      getTableProfiles({ skip: 0 });
      getSubdomainResumes();
    }
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      resetPagination();
      getTableProfiles({ skip: 0 });
    }
  }, [selectedSortBy, selectedResumeFilterOption, isAdminFilterSelected]);

  useEffect(() => {
    if (!isFirstLoad) {
      setCurrentPage(selectedPage);
      const newSkip = (selectedPage - 1) * numberOfProfilesPerPage;
      setSkipBy(newSkip);
      getTableProfiles({ skip: newSkip });
    }
  }, [selectedPage]);

  useEffect(() => {
    if (!isFirstLoad) {
      const delaySearch = setTimeout(() => {
        resetPagination();
        getTableProfiles({ search: keywordFilter, skip: 0 });
      }, 250);
      return () => clearTimeout(delaySearch);
    }
  }, [keywordFilter]);

  const isLoaded = areProfilesLoaded && areResumesLoaded;

  return (
    <StyledCard>
      <Header component="h2" type="subtitle" bold>
        Profiles
      </Header>
      <HideContentOnMobile message="Please view the list of profiles on desktop!">
        {hasError ? (
          <Error>We are having trouble loading profiles. Please try again later!</Error>
        ) : (
          <Fragment>
            <SearchFilterWrapper>
              <SearchInput
                placeholder={locale.profilesTable.placeholder[nation]}
                onChange={({ target: { value } }) => setKeywordFilter(value)}
                aria-label="Search profiles by name or email"
                value={keywordFilter}
              />
              {requiresResumeApproval && (
                <SelectAutoCompleteMargin>
                  <SelectAutoComplete
                    items={Object.keys(resumeFilterBubbles)}
                    placeholder={`Filter ${locale.resume[nation]}s`}
                    selectedValue={
                      resumeFilterToParam[selectedResumeFilterOption]
                        ? selectedResumeFilterOption
                        : `Filter ${locale.resume[nation]}s`
                    }
                    hasNoSearch
                    icons={resumeFilterBubbles}
                    isResumeFilter
                    ariaLabel="Filter by Résumé Status"
                    onChange={selectedOption =>
                      selectedOption && setSelectedResumeFilterOption(selectedOption)
                    }
                  />
                </SelectAutoCompleteMargin>
              )}
              <Toggle
                type="checkbox"
                checked={isAdminFilterSelected}
                onChange={() => setIsAdminFilterSelected(!isAdminFilterSelected)}
                label="Show Admins Only"
              />
            </SearchFilterWrapper>
            {isLoaded && profileData.length === 0 ? (
              <Message>No Profiles Found</Message>
            ) : (
              <Loading loaded={profileData.length || isLoaded}>
                <ProfilesTable
                  data={profileData}
                  defaultSortField={defaultSortField}
                  getTableProfiles={getTableProfiles}
                  isLoading={!areProfilesLoaded}
                  nation={nation}
                  requiresResumeApproval={requiresResumeApproval}
                  setDefaultSortField={setDefaultSortField}
                  setSelectedSortBy={setSelectedSortBy}
                  subdomain={subdomain}
                  resetPagination={resetPagination}
                  resumes={resumes}
                  {...tableProps}
                />
              </Loading>
            )}
            {profilesCount > 0 && (
              <Paginate
                isLoading={!areProfilesLoaded}
                page={currentPage}
                total={profilesCount}
                limit={numberOfProfilesPerPage}
                goToPage={setSelectedPage}
              />
            )}
          </Fragment>
        )}
      </HideContentOnMobile>
    </StyledCard>
  );
};

ProfilesTableCard.propTypes = {
  nation: PropTypes.string.isRequired,
  requiresResumeApproval: PropTypes.bool,
  subdomain: PropTypes.string
};

export default ProfilesTableCard;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchPostingAnalytics } from '../../../services/postings';
import { ExcelDownloadButton, ExcelStatisticsWorkbook } from 'components';

const StatisticsDownload = ({
  data,
  getData,
  employerPortal,
  hasAssessment,
  hasJobPostings,
  hasPrograms,
  hasSkillsAssessment,
  isLoaded,
  isLoading,
  requestEmail,
  resumeBuilder,
  subdomain,
  timeframe
}) => {
  const [postingAnalyticsData, setPostingAnalyticsData] = useState({});
  const [isPostingDataLoading, setIsPostingDataLoading] = useState(false);

  const getPostingsData = async () => {
    setIsPostingDataLoading(true);
    const newPostingAnalyticsData = await fetchPostingAnalytics({ subdomain, timeframe });
    setPostingAnalyticsData(newPostingAnalyticsData);
    setIsPostingDataLoading(false);
  };

  return (
    <Fragment>
      <ExcelDownloadButton
        isLoading={isLoading || isPostingDataLoading}
        handleClick={() => {
          getPostingsData();
          getData({ subdomain, timeframe });
        }}
      >
        Download Report
      </ExcelDownloadButton>

      {isLoaded && (
        <ExcelStatisticsWorkbook
          data={data}
          postingAnalyticsData={postingAnalyticsData}
          employerPortal={employerPortal}
          hasAssessment={hasAssessment}
          hasPrograms={hasPrograms}
          hasJobPostings={hasJobPostings}
          hasSkillsAssessment={hasSkillsAssessment}
          requestEmail={requestEmail}
          resumeBuilder={resumeBuilder}
        />
      )}
    </Fragment>
  );
};

StatisticsDownload.propTypes = {
  data: PropTypes.object,
  getData: PropTypes.func.isRequired,
  employerPortal: PropTypes.bool,
  hasAssessment: PropTypes.bool.isRequired,
  hasJobPostings: PropTypes.bool.isRequired,
  hasPrograms: PropTypes.bool.isRequired,
  hasSkillsAssessment: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  requestEmail: PropTypes.string,
  resumeBuilder: PropTypes.bool.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.object.isRequired
};

StatisticsDownload.defaultProps = {
  isLoaded: false,
  isLoading: false,
  data: []
};

export default StatisticsDownload;

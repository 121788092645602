const removeForbiddenWindowsCharacters = filename => {
  /* 
    These characters cannot be used in file names lest they be ignored by Windows
  */
  const forbiddenWindowsCharacters = ['<', '>', ':', '"', '/', '\\', '|', '?', '*'];
  let newFilename = filename;
  for (let index = 0; index < forbiddenWindowsCharacters.length; index++) {
    const currentForbiddenCharacter = forbiddenWindowsCharacters[index];
    newFilename = newFilename.replaceAll(currentForbiddenCharacter, '');
  }
  return newFilename;
};

export default removeForbiddenWindowsCharacters;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import { Toggle } from 'components';

const List = styled.ul`
  max-height: 26rem;
  margin: 4rem 1rem 2.5rem;
  overflow-y: scroll;
  overflow: overlay;

  :-webkit-scrollbar-track {
    display: none;
    background-color: var(--custom-white);
  }
`;

const ListItem = styled.li`
  display: flex;
  position: relative;
  margin-left: 1rem;
`;

const toggleProgram = ({ code, selectedLocalProgramsByCode, setSelectedLocalProgramsByCode }) => {
  setSelectedLocalProgramsByCode({
    ...selectedLocalProgramsByCode,
    [code]: !selectedLocalProgramsByCode[code]
  });
};

const ProgramList = ({
  currentSite,
  filteredPrograms,
  hasManyInstitutions,
  institutionsById,
  selectedInstitution,
  selectedLocalProgramsByCode,
  setSelectedLocalProgramsByCode
}) => {
  return (
    <List containerId="containerElement">
      {filteredPrograms
        .filter(({ institutionId }) =>
          !!selectedInstitution && selectedInstitution !== 'pristine'
            ? institutionId === selectedInstitution
            : true
        )
        .map(({ credential, name, code, institutionId }, index) => {
          const showInstitutionName = hasManyInstitutions && !selectedInstitution;
          const institutionName =
            hasManyInstitutions &&
            `${institutionsById[`${currentSite}-${institutionId}`].displayName}`;

          const programLabel = `${name} ${!!credential ? ` - ${credential}` : ''} ${
            showInstitutionName ? `(${institutionName})` : ''
          }`;
          return (
            <ListItem
              key={`program-select-${code}`}
              className={/* for AlphabeticalScrollThrough */ code}
            >
              <Toggle
                checked={selectedLocalProgramsByCode[code]}
                color={color.sapphireD20}
                dataCy={`toggle-${index}`}
                label={programLabel}
                onChange={() =>
                  toggleProgram({
                    code,
                    selectedLocalProgramsByCode,
                    setSelectedLocalProgramsByCode
                  })
                }
                type="checkbox"
              />
            </ListItem>
          );
        })}
    </List>
  );
};

ProgramList.propTypes = {
  currentSite: PropTypes.string.isRequired,
  filteredPrograms: PropTypes.array.isRequired,
  hasManyInstitutions: PropTypes.bool,
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedInstitution: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selectedLocalProgramsByCode: PropTypes.object.isRequired,
  setSelectedLocalProgramsByCode: PropTypes.func.isRequired
};

export default ProgramList;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fetchAlumniPathwaysData } from '../../../services';

import { generateAlumniEmbedCode } from '../../../helpers/widget-embed-code-utils';
import {
  AlumniWidgetBuilderPreview,
  AlumniWidgetBuilderSidebar,
  EmptySelectionPrompt,
  Layout,
  WidgetBuilderTabHeader,
  WidgetBuilderEmbed
} from 'components';

const Wrapper = styled.div`
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-top: 2.5rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem 0 2.5rem 2rem;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;

  @media (min-width: 740px) {
    flex-direction: row;
  }
`;

const WidgetBuilderOutput = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
  overflow-x: auto;
`;

const EmbedWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const AlumniWidgetBuilder = ({ siteSettings }) => {
  const { currentSite, primaryColor } = siteSettings;

  const [configurations, setConfigurations] = useState({
    accordionHeadingText: 'Have These Skills',
    accordionType: 'skills',
    carouselHeadingText: 'Working For These Companies',
    clientId: currentSite,
    geographyLevel: 'nation',
    graduationTimeframeStart: '1999',
    graduationTimeframeEnd: 'current',
    hasAccordion: true,
    hasOverview: true,
    hasCarousel: true,
    hasSalaryRange: true,
    hasSalary: true,
    hasEmployedInField: true,
    hasSubsequentAttainment: true,
    headingText: 'Alumni Outcomes',
    isBatch: false,
    isOnlyAfterGraduation: false,
    overviewHeadingText: 'Overview',
    primaryColor: primaryColor,
    selectedCampus: 'none',
    selectedDegreeLevel: '',
    selectedOccupations: '',
    selectedCompanies: '',
    selectedSkills: '',
    selectedGeography: '0',
    subsequentAttainmentLevel: 'bachelors'
  });

  const [isPreview, setIsPreview] = useState(true);

  const [isLoadingAlumniData, setIsLoadingAlumniData] = useState(false);
  const [isAlumniDataLoaded, setIsAlumniDataLoaded] = useState(false);
  const [degreeLevelsData, setDegreeLevelsData] = useState();
  const [employersData, setEmployersData] = useState();
  const [occupationsData, setOccupationsData] = useState();
  const [programsData, setProgramsData] = useState();
  const [skillsData, setSkillsData] = useState();

  const [selectedPrograms, setSelectedPrograms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        degreeLevels,
        employers,
        occupations,
        programs,
        skills
      } = await fetchAlumniPathwaysData(currentSite);

      const newDegreeLevelsData = degreeLevels.map(({ id, title }) => ({
        label: title,
        value: id
      }));
      setDegreeLevelsData(newDegreeLevelsData);
      setEmployersData(employers);
      setOccupationsData(occupations);
      setProgramsData(programs);
      setSkillsData(skills);
      setIsLoadingAlumniData(false);
      setIsAlumniDataLoaded(true);
    };
    if (!isLoadingAlumniData && !isAlumniDataLoaded) {
      setIsLoadingAlumniData(true);
      fetchData();
    }
  }, []);

  return (
    <Layout title="Alumni Pathways Widget Builder">
      <Wrapper>
        <Card>
          <AlumniWidgetBuilderSidebar
            configurations={configurations}
            siteSettings={siteSettings}
            setConfigurations={setConfigurations}
            degreeLevelsData={degreeLevelsData}
            employersData={employersData}
            occupationsData={occupationsData}
            skillsData={skillsData}
            programsData={programsData}
            selectedPrograms={selectedPrograms}
            setSelectedPrograms={setSelectedPrograms}
          />
          <WidgetBuilderOutput>
            <WidgetBuilderTabHeader
              configurations={configurations}
              isPreview={isPreview}
              setIsPreview={setIsPreview}
              siteSettings={siteSettings}
            />
            {!isPreview && (
              <EmbedWrapper>
                <WidgetBuilderEmbed
                  id="alumni"
                  embedCode={generateAlumniEmbedCode({
                    clientId: currentSite,
                    configurations: {
                      ...configurations,
                      ...siteSettings
                    },
                    selectedPrograms
                  })}
                />
              </EmbedWrapper>
            )}
            {isPreview && !selectedPrograms.length && <EmptySelectionPrompt />}
            <AlumniWidgetBuilderPreview
              isHidden={!isPreview}
              configurations={configurations}
              siteSettings={siteSettings}
              selectedPrograms={selectedPrograms}
            />
          </WidgetBuilderOutput>
        </Card>
      </Wrapper>
    </Layout>
  );
};

AlumniWidgetBuilder.propTypes = {
  siteSettings: PropTypes.shape({
    nation: PropTypes.string.isRequired
  })
};

export default AlumniWidgetBuilder;

import React from 'react';
import PropTypes from 'prop-types';

import { DownloadButton } from 'components';
import { downloadPdf } from '../../../helpers/downloadPdf';
import { fetchSkillsAssessmentDownload } from '../../../services/assessment';

const SkillsAssessmentDownloadButton = ({ dataCy, id, nation, name, skills }) => {
  const userName = name === '--- ---' ? '' : name;
  const handleClick = async () => {
    const downloadResponse = await fetchSkillsAssessmentDownload({
      nation,
      name: userName,
      skills
    });
    downloadPdf(downloadResponse, 'skills');
  };

  return (
    <DownloadButton
      download={() => handleClick()}
      dataCy={dataCy + '-' + userName.split(' ')[0]}
      id={id}
      name={userName}
      type="skills assessment results"
    />
  );
};

SkillsAssessmentDownloadButton.propTypes = {
  dataCy: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nation: PropTypes.string.isRequired,
  skills: PropTypes.array.isRequired
};

export default SkillsAssessmentDownloadButton;

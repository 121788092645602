import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AlumniWidgetBuilderDegreeLevel,
  AlumniWidgetBuilderOccupationsAndSkills,
  AlumniWidgetBuilderPrograms,
  HorizontalRule,
  WidgetBuilderSelect,
  WidgetBuilderToggle,
  WidgetBuilderExportTypeToggle
} from 'components';

const Sidebar = styled.div`
  min-width: 35rem;
  padding: 0 2rem 2rem 1rem;
  border-right: 2px solid #f0f1f3;
  overflow-y: auto;
`;

const Header = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  font-weight: 700;
`;

const AlumniWidgetBuilderSidebar = props => {
  const { configurations, setConfigurations } = props;
  const { hasSubsequentAttainment, primaryColor } = configurations;

  return (
    <Sidebar>
      <Header>Select Alumni Data</Header>
      <WidgetBuilderExportTypeToggle {...props} />
      <HorizontalRule />
      <AlumniWidgetBuilderPrograms {...props} />
      <HorizontalRule />
      <AlumniWidgetBuilderDegreeLevel
        configurations={configurations}
        setConfigurations={setConfigurations}
        {...props}
      />
      <HorizontalRule />
      <WidgetBuilderToggle
        id="has-subsequent-attainment-level"
        label="Subsequent Attainment Level"
        handleChange={() =>
          setConfigurations({
            ...configurations,
            hasSubsequentAttainment: !hasSubsequentAttainment
          })
        }
        isChecked={hasSubsequentAttainment}
      />
      {hasSubsequentAttainment && (
        <WidgetBuilderSelect
          configurations={configurations}
          id="subsequent-attainment-level"
          label="Select degree level"
          optionsList={[
            { label: 'Bachelors', value: 'bachelors' },
            { label: 'Graduate', value: 'graduate' }
          ]}
          selectionLabel="subsequentAttainmentLevel"
          setConfigurations={setConfigurations}
          {...props}
        />
      )}
      <HorizontalRule />
      <AlumniWidgetBuilderOccupationsAndSkills
        configurations={configurations}
        setConfigurations={setConfigurations}
        {...props}
      />
      <HorizontalRule />
    </Sidebar>
  );
};

AlumniWidgetBuilderSidebar.propTypes = {
  configurations: PropTypes.shape({
    primaryColor: PropTypes.string
  }).isRequired,
  currentSite: PropTypes.string,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderSidebar;

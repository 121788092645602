import React from 'react';
import PropTypes from 'prop-types';
import { color } from '../../../styles';
import { Card, TotalChart } from 'components';

const { darkGray } = color;

const VisitsChart = ({ data, isLoaded, nation }) => {
  return (
    <Card>
      <TotalChart
        a11yHeader="Total Visits and Visitor"
        data={data}
        chartId="visits-and-visitors"
        color={darkGray}
        nation={nation}
        isLoaded={isLoaded}
      />
    </Card>
  );
};

VisitsChart.propTypes = {
  data: PropTypes.array,
  isLoaded: PropTypes.bool,
  nation: PropTypes.string.isRequired
};

export default VisitsChart;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WidgetBuilderProgramModal } from 'components';
import { fetchProgramsBySubdomain } from '../../../services';

const sortProgramsAlphabetically = ({
  currentSite,
  hasManyInstitutions,
  institutionsById,
  programs
}) =>
  programs.sort((alpha, bravo) => {
    const makeSortableString = ({ institutionId, slug }) =>
      `${slug}-${
        hasManyInstitutions ? institutionsById[`${currentSite}-${institutionId}`].displayName : ''
      }`;

    const alphaValue = makeSortableString(alpha);
    const bravoValue = makeSortableString(bravo);

    if (alphaValue < bravoValue) {
      return -1;
    }
    if (alphaValue > bravoValue) {
      return 1;
    }
    return 0;
  });

export const fetchProgramsForWidget = async ({
  currentSite,
  hasManyInstitutions,
  institutionsById,
  nation,
  setPrograms,
  setFilteredPrograms,
  setIsProgramsLoading
}) => {
  setIsProgramsLoading(true);
  const programs = await fetchProgramsBySubdomain({ subdomain: currentSite, nation });
  const sortedResult = sortProgramsAlphabetically({
    currentSite,
    hasManyInstitutions,
    institutionsById,
    programs
  });
  setPrograms(sortedResult);
  setFilteredPrograms(sortedResult);
  setIsProgramsLoading(false);
};

const ProgramWidgetBuilderProgramModal = ({
  currentSite,
  institutionsById,
  nation,
  selectedPrograms,
  toggleIsModalOpen,
  ...props
}) => {
  const [selectedLocalProgramsByCode, setSelectedLocalProgramsByCode] = useState({});
  const [programs, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [isProgramsLoading, setIsProgramsLoading] = useState(false);

  useEffect(() => {
    if (!programs.length) {
      fetchProgramsForWidget({
        currentSite,
        institutionsById,
        nation,
        setPrograms,
        setFilteredPrograms,
        setIsProgramsLoading
      });
    }
  }, [programs]);

  useEffect(() => {
    const selectedProgramsObject = {};
    for (let index = 0; index < selectedPrograms.length; index++) {
      const { code } = selectedPrograms[index];
      selectedProgramsObject[code] = true;
    }
    setSelectedLocalProgramsByCode(selectedProgramsObject);
    setFilteredPrograms(programs);
  }, [selectedPrograms, programs, setSelectedLocalProgramsByCode, toggleIsModalOpen]);

  return (
    <WidgetBuilderProgramModal
      programs={programs}
      institutionsById={institutionsById}
      currentSite={currentSite}
      nation={nation}
      toggleIsModalOpen={toggleIsModalOpen}
      filteredPrograms={filteredPrograms}
      setFilteredPrograms={setFilteredPrograms}
      isProgramsLoading={isProgramsLoading}
      setIsProgramsLoading={setIsProgramsLoading}
      selectedLocalProgramsByCode={selectedLocalProgramsByCode}
      setSelectedLocalProgramsByCode={setSelectedLocalProgramsByCode}
      {...props}
    />
  );
};

ProgramWidgetBuilderProgramModal.propTypes = {
  currentSite: PropTypes.string.isRequired,
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  nation: PropTypes.string.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default ProgramWidgetBuilderProgramModal;

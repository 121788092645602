import React from 'react';
import PropTypes from 'prop-types';

import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';

import { AlphabeticalScrollTo, Loading, Modal } from 'components';
import { filterByKeyword, handleSave, selectAllPrograms } from './functions';
import {
  ButtonsWrapper,
  Card,
  DeselectAllIcon,
  Header,
  SelectAllButtonsWrapper,
  SelectAllButton,
  SearchIcon,
  SelectAllIcon,
  StyledButton,
  StyledInput,
  Title
} from './styled';
import ProgramList from './ProgramList';

const { darkerGray, gray, sapphireD20, white } = color;

const WidgetBuilderProgramModal = ({
  currentSite,
  hasManyInstitutions,
  institutionsById,
  isOpen,
  nation,
  selectedInstitution = false,
  setSelectedPrograms,
  toggleIsModalOpen,
  selectedLocalProgramsByCode,
  setSelectedLocalProgramsByCode,
  programs,
  filteredPrograms,
  setFilteredPrograms,
  isProgramsLoading
}) => {
  const lowercaseEdTrack = locale.edTrack[nation].toLowerCase();

  return (
    <Modal isOpen={isOpen} close={toggleIsModalOpen} hasCloseButton={false} height="90%">
      <Card>
        <Header>
          <Title>{`Select ${locale.edTrack[nation]}s`}</Title>
          <div>{`Select one or multiple ${lowercaseEdTrack}s to see career-related data appear in your widget`}</div>

          <div>
            <SearchIcon size="2" type="search" hasHover={false} />
            <StyledInput
              aria-label={`Search for a ${lowercaseEdTrack}`}
              onChange={({ target: { value } }) =>
                filterByKeyword({ keyword: value, programs, setFilteredPrograms })
              }
              placeholder={`Search for a ${lowercaseEdTrack}`}
            />
          </div>
          <AlphabeticalScrollTo filteredPrograms={filteredPrograms} />
          <SelectAllButtonsWrapper>
            <SelectAllButton
              onClick={() =>
                selectAllPrograms({ filteredPrograms, setSelectedLocalProgramsByCode, value: true })
              }
            >
              <SelectAllIcon aria-hidden="true" />
              Select All
            </SelectAllButton>
            <SelectAllButton
              onClick={() =>
                selectAllPrograms({
                  filteredPrograms,
                  setSelectedLocalProgramsByCode,
                  value: false
                })
              }
            >
              <DeselectAllIcon aria-hidden="true" />
              Deselect All
            </SelectAllButton>
          </SelectAllButtonsWrapper>
        </Header>
        <Loading loaded={!isProgramsLoading}>
          <ProgramList
            currentSite={currentSite}
            filteredPrograms={filteredPrograms}
            hasManyInstitutions={hasManyInstitutions}
            institutionsById={institutionsById}
            selectedInstitution={selectedInstitution}
            selectedLocalProgramsByCode={selectedLocalProgramsByCode}
            setSelectedLocalProgramsByCode={setSelectedLocalProgramsByCode}
          />
        </Loading>
        <ButtonsWrapper>
          <StyledButton color={darkerGray} background={gray} onClick={toggleIsModalOpen}>
            Cancel
          </StyledButton>
          <StyledButton
            color={white}
            background={sapphireD20}
            data-cy="program-widget-program-modal-save-button"
            onClick={() =>
              handleSave({
                programs,
                selectedLocalProgramsByCode,
                setSelectedPrograms,
                toggleIsModalOpen
              })
            }
          >
            Apply Changes
          </StyledButton>
        </ButtonsWrapper>
      </Card>
    </Modal>
  );
};

WidgetBuilderProgramModal.propTypes = {
  currentSite: PropTypes.string.isRequired,
  filteredPrograms: PropTypes.array.isRequired,
  hasManyInstitutions: PropTypes.bool,
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool,
  isProgramsLoading: PropTypes.bool,
  nation: PropTypes.string.isRequired,
  programs: PropTypes.array,
  selectedInstitution: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selectedLocalProgramsByCode: PropTypes.object.isRequired,
  selectedPrograms: PropTypes.array,
  setFilteredPrograms: PropTypes.func.isRequired,
  setSelectedLocalProgramsByCode: PropTypes.func.isRequired,
  setSelectedPrograms: PropTypes.func.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default WidgetBuilderProgramModal;

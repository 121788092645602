export const combineAddedSkillsByOriginData = data => {
  const origins = [
    'education-skills-added',
    'work-experience-skills-added',
    'additional-skills-added'
  ];
  const programCountsByOrigin = {};
  for (let index = 0; index < origins.length; index++) {
    const currentOrigin = origins[index];

    if (!data[currentOrigin]) {
      continue;
    }

    for (let jndex = 0; jndex < data[currentOrigin].length; jndex++) {
      const currentDatum = data[currentOrigin][jndex];
      const skillName = currentDatum.label;
      const originTotal = currentDatum.result;
      if (!programCountsByOrigin[skillName]) {
        programCountsByOrigin[skillName] = {
          label: skillName,
          [currentOrigin]: originTotal
        };
      } else {
        programCountsByOrigin[skillName][currentOrigin] = originTotal;
      }
    }
  }
  return Object.values(programCountsByOrigin);
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  generateAlternativeProgramWidgetEmbedCode,
  generateStandardEmbedCode,
  generateProgramWidgetSrcUrl
} from '../../../helpers/widget-embed-code-utils';

import { DichotomousSlider, EmptySelectionPrompt, Hint, WidgetBuilderEmbedCode } from 'components';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 57rem;
  padding: 3.5rem;
  padding-top: 1rem;
  overflow-y: scroll;
  background: white;
`;

const ToggleAndTooltipWrapper = styled.div`
  display: flex;
  margin-bottom: 5rem;
`;

const generateEmbedCode = ({
  configurations,
  isAlternativeEmbedCode,
  selectedPrograms,
  siteSettings
}) => {
  if (isAlternativeEmbedCode) {
    const alternativeEmbedCode = generateAlternativeProgramWidgetEmbedCode({
      configurations,
      siteSettings,
      programs: selectedPrograms
    });

    return alternativeEmbedCode;
  } else {
    const standardEmbedCode = generateStandardEmbedCode({
      configurations,
      siteSettings,
      programs: selectedPrograms
    });
    return standardEmbedCode;
  }
};

const ProgramWidgetBuilderEmbed = ({
  configurations,
  isAlternativeEmbedCode,
  selectedPrograms,
  setIsAlternativeEmbedCode,
  siteSettings
}) => {
  const { isBatch } = configurations;

  if (!selectedPrograms.length) {
    return <EmptySelectionPrompt />;
  }

  const { currentSite } = siteSettings;
  const previewWidgetUrl = generateProgramWidgetSrcUrl({
    configurations,
    currentSite,
    selectedPrograms,
    siteSettings
  });

  return (
    <Wrapper>
      <ToggleAndTooltipWrapper>
        <DichotomousSlider
          handleChange={() => setIsAlternativeEmbedCode(!isAlternativeEmbedCode)}
          isChecked={isAlternativeEmbedCode}
          checkedOptionLabel="Alternative"
          uncheckedOptionLabel="Standard"
        />
        <Hint id="embed-code-type-tooltip">
          Our standard embed code implements well with many CMS platforms. If you're using a
          proprietary platform like Adobe Experience Manager or would prefer the iframe builds on
          load, please try the alternative embed code.
        </Hint>
      </ToggleAndTooltipWrapper>
      {!isBatch && (
        <WidgetBuilderEmbedCode
          id={`embed-code-${selectedPrograms.map(({ name }) => name)}`}
          embedCode={generateEmbedCode({
            configurations,
            isAlternativeEmbedCode,
            previewWidgetUrl,
            selectedPrograms,
            siteSettings
          })}
        />
      )}
      {isBatch &&
        selectedPrograms.map(program => (
          <WidgetBuilderEmbedCode
            key={`embed-code-${program.code}`}
            id={`embed-code-${program.name}`}
            embedCode={generateEmbedCode({
              configurations,
              isAlternativeEmbedCode,
              previewWidgetUrl,
              selectedPrograms: [program],
              siteSettings
            })}
          />
        ))}
    </Wrapper>
  );
};

ProgramWidgetBuilderEmbed.propTypes = {
  configurations: PropTypes.shape({
    isBatch: PropTypes.bool.isRequired
  }),
  isAlternativeEmbedCode: PropTypes.bool.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  setIsAlternativeEmbedCode: PropTypes.func.isRequired,
  siteSettings: PropTypes.object.isRequired
};

export default ProgramWidgetBuilderEmbed;

import React from 'react';
import styled from 'styled-components';

const NoProgramsMessage = styled.p`
  margin-left: 3.5rem;
  margin-top: 2rem;
`;

const EmptySelectionPrompt = () => (
  <NoProgramsMessage>Select some Programs to start building your widget</NoProgramsMessage>
);

export default EmptySelectionPrompt;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WidgetBuilderEmbedCode } from 'components';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 57rem;
  padding: 3.5rem;
  padding-top: 1rem;
  background: white;
  overflow-y: scroll;
`;

const WidgetBuilderEmbed = ({ embedCode, id }) => (
  <Wrapper>
    <WidgetBuilderEmbedCode id={id} embedCode={embedCode} />
  </Wrapper>
);

WidgetBuilderEmbed.propTypes = {
  id: PropTypes.string.isRequired,
  embedCode: PropTypes.string.isRequired
};

export default WidgetBuilderEmbed;

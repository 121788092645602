import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { color } from '../../../styles';
import {
  keenAdditionalSkillsAdded,
  keenEducationSkillsAdded,
  keenWorkExperienceSkillsAdded
} from '../../../services/keen/queries';
import { Button, Card } from 'components';
import { FetchKeen } from 'containers';
import SkillsByTypeTable from './SkillsByTypeTable';

const { black, lightGray } = color;

const Header = styled.h2`
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  padding: 0;
  min-height: 67rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  border-bottom: none;

  @media (min-width: 450px) {
    flex-direction: row;
    width: 100%;
    border-bottom: 4px solid ${lightGray};
  }
`;

const TabButton = styled(Button)`
  margin: 0.25rem 0.75rem -0.4rem 0;
  border-radius: 0;
  border-bottom: 4px solid ${({ isSelected, themeColor }) => (isSelected ? themeColor : lightGray)};
  color: ${({ isSelected, themeColor }) => (isSelected ? themeColor : black)};
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '400')};
  letter-spacing: ${({ isSelected }) => (isSelected ? 'normal' : '.7px')};
`;

const SkillsByTypeCard = ({ themeColor }) => {
  const [selectedTableIndex, setSelectedTableIndex] = useState(0);

  return (
    <Fragment>
      <Header>Top Skills Selected From</Header>
      <StyledCard>
        <ButtonWrapper>
          <TabButton
            isInverted
            isSelected={selectedTableIndex === 0}
            arial-label="view top skills from education table"
            onClick={() => setSelectedTableIndex(0)}
            themeColor={themeColor}
            data-cy="top-skills-from-education-button"
          >
            Education
          </TabButton>
          <TabButton
            isInverted
            isSelected={selectedTableIndex === 1}
            arial-label="view top skills from work experience table"
            onClick={() => setSelectedTableIndex(1)}
            themeColor={themeColor}
            data-cy="top-skills-from-work-experience-button"
          >
            Work
          </TabButton>
          <TabButton
            isInverted
            isSelected={selectedTableIndex === 2}
            arial-label="view top skills from additional table"
            onClick={() => setSelectedTableIndex(2)}
            themeColor={themeColor}
            data-cy="top-skills-from-additional-button"
          >
            Additional
          </TabButton>
        </ButtonWrapper>
        {selectedTableIndex === 0 && (
          <FetchKeen {...keenEducationSkillsAdded}>
            {({ data, loaded }) => (
              <SkillsByTypeTable tableData={data} isLoaded={loaded} skillType="education" />
            )}
          </FetchKeen>
        )}
        {selectedTableIndex === 1 && (
          <FetchKeen {...keenWorkExperienceSkillsAdded}>
            {({ data, loaded }) => (
              <SkillsByTypeTable tableData={data} isLoaded={loaded} skillType="work-experience" />
            )}
          </FetchKeen>
        )}
        {selectedTableIndex === 2 && (
          <FetchKeen {...keenAdditionalSkillsAdded}>
            {({ data, loaded }) => (
              <SkillsByTypeTable tableData={data} isLoaded={loaded} skillType="additional" />
            )}
          </FetchKeen>
        )}
      </StyledCard>
    </Fragment>
  );
};

SkillsByTypeCard.propTypes = {
  themeColor: PropTypes.string.isRequired
};

export const mapStateToProps = ({ sites }) => ({
  themeColor: sites.items[sites.currentSite].styles.accentColor
});

export default connect(mapStateToProps, null)(SkillsByTypeCard);

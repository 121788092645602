import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import {
  keenAssessmentWidgetCareerLinkClicks,
  keenAssessmentWidgetCareerLinkClicksByCareer,
  keenAssessmentWidgetDownloads,
  keenAssessmentWidgetProgramLinkClicks,
  keenAssessmentWidgetProgramLinkClicksByProgram
} from '../../../services/keen/queries';
import { withSiteHOC } from '../../../hocs/withSite';

import {
  AssessmentWidgetCareerCoachVisitsTable,
  AssessmentWidgetProgramVisitsTable,
  FetchKeen
} from 'containers';
import AssessmentStartsCompletionsGraph from '../../../containers/AssessmentStartsCompletionsGraph';
import { AnalyticsMiniChart } from 'components';

const { goldYellow, turquoise, purple } = color;

const Card = styled.div`
  padding: 4rem 2rem 2.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:last-child {
    margin-right: 0;
  }
`;

const StyledAssessmentStartsCompletionsGraph = styled(AssessmentStartsCompletionsGraph)`
  margin-bottom: 4rem;
`;

const StyledAnalyticsMiniChart = styled(AnalyticsMiniChart)`
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Tables = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const AssessmentWidgetStatistics = withSiteHOC()(({ nation, enabled: isCareerCoachEnabled }) => {
  return (
    <Card>
      <StyledAssessmentStartsCompletionsGraph nation={nation} />
      <Row>
        <StyledAnalyticsMiniChart
          dataLabel="downloads"
          heading="Downloads"
          color={goldYellow}
          keenQuery={keenAssessmentWidgetDownloads}
        />
        <StyledAnalyticsMiniChart
          dataLabel="clicks"
          heading="Program Page Clicks"
          color={turquoise}
          keenQuery={keenAssessmentWidgetProgramLinkClicks}
        />
        {isCareerCoachEnabled && (
          <StyledAnalyticsMiniChart
            dataLabel="clicks"
            heading="Career Coach Clicks"
            color={purple}
            keenQuery={keenAssessmentWidgetCareerLinkClicks}
          />
        )}
      </Row>
      <Tables>
        <FetchKeen {...keenAssessmentWidgetProgramLinkClicksByProgram}>
          {props => <AssessmentWidgetProgramVisitsTable {...props} />}
        </FetchKeen>
        {isCareerCoachEnabled && (
          <FetchKeen {...keenAssessmentWidgetCareerLinkClicksByCareer}>
            {props => <AssessmentWidgetCareerCoachVisitsTable {...props} />}
          </FetchKeen>
        )}
      </Tables>
    </Card>
  );
});

AssessmentWidgetStatistics.propTypes = {
  enabled: PropTypes.bool,
  nation: PropTypes.string
};

export default AssessmentWidgetStatistics;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SliderToggle } from 'components';

const Label = styled.label`
  display: flex;
  font-weight: 600;
`;

const WidgetBuilderToggle = ({ handleChange, label, isChecked, isDisabled }) => (
  <Label>
    <SliderToggle
      ariaLabel={`${isChecked ? 'disable' : 'enable'} ${label}`}
      handleChange={handleChange}
      isChecked={isChecked}
      isDisabled={isDisabled}
    />
    {label}
  </Label>
);

WidgetBuilderToggle.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool
};

export default WidgetBuilderToggle;

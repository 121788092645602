import WidgetBuilderDashboard from '../../components/pages/WidgetBuilderDashboard';
import { connect } from 'react-redux';

export const mapStateToProps = ({ sites }) => {
  const { currentSite, items } = sites;

  const site = items[currentSite];

  const {
    alumniWidget: { isEnabled: isAlumniWidgetEnabled },
    assessmentWidget: { isEnabled: isAssessmentWidgetEnabled },
    programWidget: { isEnabled: isProgramWidgetEnabled }
  } = site.widgetBuilderSettings;

  return {
    isAlumniWidgetEnabled,
    isAssessmentWidgetEnabled,
    isProgramWidgetEnabled,
    nation: !!site ? site.nation : 'us'
  };
};

export default connect(mapStateToProps)(WidgetBuilderDashboard);

import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../../helpers/locale';
import { FormRadio } from 'components';

const WidgetBuilderExportTypeToggle = ({ configurations, setConfigurations, siteSettings }) => {
  const { nation } = siteSettings;
  const { isBatch } = configurations;
  const localizedEdTrack = locale.edTrack[nation].toLowerCase();
  return (
    <FormRadio
      label="Export Type"
      options={[
        {
          ariaLabel: 'Single export type',
          label: 'Single',
          value: false
        },
        {
          ariaLabel: 'Batch export type',
          label: 'Batch',
          value: true
        }
      ]}
      input={{
        onChange: () => setConfigurations({ ...configurations, isBatch: !isBatch }),
        value: isBatch
      }}
      color="black"
      hintText={`Single Export will show career data for each ${localizedEdTrack} selected in a single widget.
        Batch Export will create a separate widget for each ${localizedEdTrack} selected below.`}
    />
  );
};

WidgetBuilderExportTypeToggle.propTypes = {
  configurations: PropTypes.shape({
    isBatch: PropTypes.bool.isRequired
  }).isRequired,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    nation: PropTypes.string.isRequired
  })
};

export default WidgetBuilderExportTypeToggle;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Sort from '../../../images/sort.svg';
import SortDown from '../../../images/sort-down.svg';
import SortUp from '../../../images/sort-up.svg';
import TooltipIcon from '../../../images/blue-i.svg';

import { Hint, Paginate } from 'components';
import {
  Cell,
  FooterTotal,
  HeaderAndToolTip,
  HeaderCell,
  HeaderWrapper,
  PaginateWrapper,
  Row,
  RowDrawer,
  RowDrawerContents,
  SortButton,
  Table,
  TableFooter,
  TableWrapper
} from './styled';

/* ProgramTableCategoryFilter ought not be in this file.. */
import ProgramTableCategoryFilter from '../ProgramTableCategoryFilter';
/* ...but we're leaving it in for now awaiting a redesign of the program category filter */

const StickyColumnTable = ({
  /* categoryId & setCategoryIds ought not be in this file.. */
  categoryIds,
  setCategoryIds,
  /* ...but we're leaving them in for now awaiting a redesign of the program category filter */

  columns: allColumns,
  categoryOptions,
  className,
  dataCy,
  determineRowColor = false,
  hasDrawers = false,
  headingRowColor,
  isLoading,
  pageNumber,
  rowDrawers,
  rowsPerPage,
  setPageNumber,
  setSortColumn,
  shouldAlternateRowColor = true,
  sortColumn,
  tableData,
  tableMaxWidth,
  totalRows
}) => {
  const columns = allColumns.filter(({ isHidden }) => !isHidden);

  const calculateOffset = (currentColumn, currentIndex) =>
    currentColumn.isSticky
      ? columns
          .filter((column, index) => index < currentIndex)
          .map(({ width }) => width)
          .reduce((a, b) => a + b, 0)
      : 0;

  const handleSortClick = id => {
    if (!sortColumn.includes(id) || sortColumn === `-${id}`) {
      setSortColumn(`${id}`);
    } else if (sortColumn === `${id}`) {
      setSortColumn(`-${id}`);
    }
  };

  return (
    <Fragment>
      <TableWrapper maxWidth={tableMaxWidth} data-cy={dataCy} className={className}>
        <Table width={tableMaxWidth}>
          <thead>
            <Row rowColor={headingRowColor}>
              {columns.map((currentColumn, currentIndex) => {
                const offset = calculateOffset(currentColumn, currentIndex);
                const {
                  hasBorderRight,
                  header,
                  id,
                  isSticky,
                  hasColumnFilter,
                  hasSort,
                  textAlign = 'left',
                  tooltip,
                  width
                } = currentColumn;
                return (
                  <HeaderCell
                    cellColor={headingRowColor}
                    data-cy={`sticky-table-header-${currentIndex}`}
                    hasBorderRight={hasBorderRight}
                    isSticky={isSticky}
                    offset={offset}
                    width={width}
                    key={`sticky-table-header-${currentIndex}`}
                    zIndex={columns.length - currentIndex}
                  >
                    <HeaderWrapper>
                      <HeaderAndToolTip
                        justifyContent={() => {
                          const alignToJustifyMap = {
                            center: 'center',
                            left: 'flex-start',
                            right: 'flex-end'
                          };
                          return alignToJustifyMap[textAlign];
                        }}
                      >
                        {header}
                        {!!tooltip && (
                          <Hint id={`sticky-table-header-tooltip-${id}`} icon={<TooltipIcon />}>
                            {tooltip}
                          </Hint>
                        )}
                      </HeaderAndToolTip>
                      {!!hasSort && !!setSortColumn && !!sortColumn && (
                        <SortButton
                          data-cy={`sticky-table--column-sort-button-${id}`}
                          onClick={() => handleSortClick(id)}
                        >
                          {sortColumn === `${id}` && <SortUp />}
                          {sortColumn === `-${id}` && <SortDown />}
                          {!sortColumn.includes(id) && <Sort />}
                        </SortButton>
                      )}
                      {!!hasColumnFilter && (
                        /* ProgramTableCategoryFilter ought not be in this file.. */
                        <ProgramTableCategoryFilter
                          categoryIds={categoryIds}
                          setCategoryIds={setCategoryIds}
                          categoryOptions={categoryOptions}
                        />
                        /* ...but we're leaving it in for now awaiting a redesign of the program category filter */
                      )}
                    </HeaderWrapper>
                  </HeaderCell>
                );
              })}
            </Row>
          </thead>
          <tbody>
            {tableData.map((rowData, rowIndex) => {
              return (
                <Fragment key={`sticky-table-row-drawer-${rowIndex}`}>
                  <Row
                    data-cy={`${dataCy}--row-${rowIndex}`}
                    rowIndex={rowIndex}
                    shouldAlternateRowColor={shouldAlternateRowColor}
                  >
                    {columns.map((currentColumn, columnIndex) => {
                      const offset = calculateOffset(currentColumn, columnIndex);
                      const {
                        hasBorderRight,
                        id,
                        isSticky,
                        textAlign,
                        value,
                        width
                      } = currentColumn;
                      return (
                        <Cell
                          hasBorderRight={hasBorderRight}
                          isSticky={isSticky}
                          key={`sticky-table-cell-${rowIndex}=${columnIndex}`}
                          offset={offset}
                          rowIndex={rowIndex}
                          rowColor={determineRowColor && determineRowColor(rowData)}
                          textAlign={textAlign}
                          width={width}
                          shouldAlternateRowColor={shouldAlternateRowColor}
                        >
                          {!!value ? value(rowData) : rowData[id]}
                        </Cell>
                      );
                    })}
                  </Row>
                  {hasDrawers && !!rowDrawers[rowData.code] && (
                    <tr>
                      <td>
                        <RowDrawer
                          data-cy={`${dataCy}--row-drawer-${rowIndex}`}
                          isOpen={rowDrawers[rowData.code].isOpen}
                          width={tableMaxWidth}
                          height={rowDrawers[rowData.code].height}
                        >
                          <RowDrawerContents isOpen={rowDrawers[rowData.code].isOpen}>
                            {rowDrawers[rowData.code].contents}
                          </RowDrawerContents>
                        </RowDrawer>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
      {totalRows > 0 && !!pageNumber && !!setPageNumber && (
        <TableFooter maxWidth={tableMaxWidth} className={className}>
          <FooterTotal aria-live="polite">{`${(pageNumber - 1) * rowsPerPage + 1}-${Math.min(
            pageNumber * rowsPerPage,
            totalRows
          )} of ${totalRows} Results`}</FooterTotal>
          <PaginateWrapper>
            <Paginate
              isLoading={isLoading}
              limit={rowsPerPage}
              page={pageNumber}
              goToPage={setPageNumber}
              total={totalRows}
            />
          </PaginateWrapper>
        </TableFooter>
      )}
    </Fragment>
  );
};

StickyColumnTable.propTypes = {
  determineRowColor: PropTypes.func,
  categoryOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.id,
      header: PropTypes.any.required,
      isSticky: PropTypes.bool,
      hasBorderRight: PropTypes.bool,
      hasSort: PropTypes.bool,
      tooltip: PropTypes.any,
      width: PropTypes.number,
      value: PropTypes.any
    })
  ).isRequired,
  categoryIds: PropTypes.array,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  hasDrawers: PropTypes.bool,
  headingRowColor: PropTypes.string,
  isLoading: PropTypes.bool,
  pageNumber: PropTypes.number,
  rowDrawers: PropTypes.object,
  rowsPerPage: PropTypes.number.isRequired,
  setCategoryIds: PropTypes.func,
  setPageNumber: PropTypes.func,
  setSortColumn: PropTypes.func,
  shouldAlternateRowColor: PropTypes.bool,
  sortColumn: PropTypes.string,
  totalRows: PropTypes.number,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableMaxWidth: PropTypes.string
};

export default StickyColumnTable;

/*  
    `columns` is an array of objects with the following properties:

      id: string, required, used to reference column for sorting and default cell data. The id should correspond to data property displayed in the column.

      header: any, required, the column header.

      isSticky: boolean, optional, if true, makes the column sticky. defaults to false.

      hasBorderRight: boolean, optional, if true, creates a border down the right side of the column. defaults to false.

      hasSort: boolean, optional, if true, makes the column sortable. defaults to false.

      tooltip: any, optional, contents of header tooltip. 

      width: number, required, the width of the column in rems.

      value: function, optional, a function that receives rowData as a prop and returns a cell's value. defaults to `rowData => rowData[id];`.


      example: 

      tableData = [ 
        { 
          titles: ['King and Tyrant', 'Dictator-For-Life'] 
        }, 
        { 
          titles: ['President', 'First Tiger'] }, ... ]
        };
   
      {
          id: 'titles',
          header: 'Titles',
          hasSort: true,
          isSticky: true,
          hasBorderRight: true,
          width: 10,
          value: rowData => rowData.titles.map(program => program.name).join()
      },


*/

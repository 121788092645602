import config from '../../config';
import Amplify from '@aws-amplify/core';
import cognitoAuth from '@aws-amplify/auth';

// Configure AWS Amplify Core
Amplify.configure({
  Auth: config.awsCognitoAuth
});

export const getCognitoToken = async () => {
  const {
    signInUserSession: {
      idToken: { jwtToken }
    }
  } = await cognitoAuth.currentAuthenticatedUser();
  return jwtToken;
};

export const signUpWithCognito = async ({ email, password, subdomain }) => {
  const data = {
    username: email,
    password,
    attributes: { email, 'custom:subdomain': subdomain, 'custom:userRole': 'admin' }
  };
  try {
    await cognitoAuth.signUp(data);
    return cognitoAuth.signIn(email, password);
  } catch (error) {
    throw error;
  }
};

/**
 * This function uses AWS Amplify library to attempt authentication with the provided email (email) and password.
 * Upon successful authentication, Amplify will set relevant token and user data in localstorage.
 * @param {string} email - The user's login email
 * @param {string} password - The user's authentication password
 * @return {object} - Returns a user data object
 */
export const logIntoCognito = async (email, password, ssoConnection) => {
  // A small function to verify admin status of a user
  const checkIfAdmin = userToCheck => {
    const adminRoles = ['admin', 'super'];
    // If the authed user is an admin, return their info.
    if (adminRoles.includes(userToCheck.attributes['custom:userRole'])) {
      return cognitoAuth.currentUserInfo();
    } else {
      // Revoke all session and tokens. No access granted.
      cognitoAuth.signOut({});
      throw new Error('User is not an administrator.');
    }
  };
  let user;
  try {
    // Check to see if there is a user already authenticated.
    user = await cognitoAuth.currentAuthenticatedUser();
  } catch (e) {
    // If not, the above function will throw an error, but that's ok. handle it here, and continue on.
    user = null;
  }
  // If a user exists, check to see if they are an admin.
  if (user) {
    return checkIfAdmin(user);
  }
  // If there is no user, but an ssoConnection was passed in, then send the user to the institutions
  // IDP for authentication.
  if (!user && ssoConnection) {
    return cognitoAuth.federatedSignIn({ provider: ssoConnection });
  }
  // if user is not logged in and no credentials or SSO info were passed,
  // return null and the app will redirect to the login page
  if (!user && !email && !password) {
    return null;
  }

  // Sign in to AWS Cognito User Pool with Amplify
  return cognitoAuth.signIn(email, password).then(response => {
    return checkIfAdmin(response);
  });
};

export const logoutCognito = () => cognitoAuth.signOut();
/**
 * sends the Cognito user an email that includes verification code to confirm identity
 * This is step 1 of Forgot Password flow on Cognito
 * @method resetPasswordRequest
 * @param  {string}           email the email of the user
 */
export const resetPasswordRequest = email => {
  return cognitoAuth.forgotPassword(email);
};

/**
 * submits the new password to Cognito
 * This is step 2 of Forgot Password flow on Cognito
 * @method resetPasswordSubmit
 * @param  {string}           email the email of the user
 * @param  {string}           verificationCode verification code sent to the user's email account
 * @param  {string}           newPassword the new password set by the user
 */
export const resetPasswordSubmit = ({ email, verificationCode, password }) => {
  return cognitoAuth.forgotPasswordSubmit(email, verificationCode, password);
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';
import Pencil from '../../../images/pencil.svg';
import {
  AlumniWidgetBuilderProgramModal,
  BoldLabel,
  Loading,
  WidgetBuilderProgramsEmptyState,
  WidgetBuilderSelectedPrograms
} from 'components';

const { sapphireD20 } = color;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const EditProgramsButton = styled.button`
  border: none;
  background: none;
  color: ${sapphireD20};
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
`;

const EditProgramsIcon = styled(Pencil)`
  height: 1.7rem;
  width: 1.7rem;
  position: relative;
  top: 0.2rem;
  margin-right: 0.75rem;
`;

const AlumniWidgetBuilderPrograms = ({
  selectedPrograms,
  setSelectedPrograms,
  siteSettings,
  programsData
}) => {
  const { currentSite, nation } = siteSettings;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const arePrograms = !!selectedPrograms.length;

  return (
    <Wrapper>
      <Header>
        <BoldLabel label={`${locale.edTrack[nation]}s`} component="legend" />
        {arePrograms && (
          <EditProgramsButton
            onClick={toggleIsModalOpen}
            aria-label={`Open the select ${locale.edTrack[nation]}s modal to add ${locale.edTrack[nation]}s to the widget.`}
          >
            <EditProgramsIcon aria-hidden="true" />
            {`Edit ${locale.edTrack[nation]}s`}
          </EditProgramsButton>
        )}
      </Header>
      <Loading loaded={true}>
        {!arePrograms && (
          <div>
            <WidgetBuilderProgramsEmptyState
              nation={nation}
              toggleIsModalOpen={toggleIsModalOpen}
            />
          </div>
        )}
        {arePrograms && (
          <WidgetBuilderSelectedPrograms
            selectedPrograms={selectedPrograms}
            setSelectedPrograms={setSelectedPrograms}
          />
        )}
        <AlumniWidgetBuilderProgramModal
          currentSite={currentSite}
          isOpen={isModalOpen}
          nation={nation}
          programsData={programsData}
          toggleIsModalOpen={toggleIsModalOpen}
          selectedPrograms={selectedPrograms}
          setSelectedPrograms={setSelectedPrograms}
        />
      </Loading>
    </Wrapper>
  );
};

AlumniWidgetBuilderPrograms.propTypes = {
  programsData: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, title: PropTypes.string.isRequired })
  ),
  selectedPrograms: PropTypes.array.isRequired,
  setSelectedPrograms: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    currentSite: PropTypes.string.isRequired,
    nation: PropTypes.string.isRequired
  })
};

export default AlumniWidgetBuilderPrograms;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { color } from '../../styles';
import { ProfilesChart } from 'components';
import { fetchKeenData } from '../../services';
import { keenProfilesCreated } from '../../services/keen/queries';
import { selectSubdomain } from '../../selectors/subdomain';

const { darkGreen } = color;

class ProfileChartContainer extends React.Component {
  static propTypes = {
    nation: PropTypes.string.isRequired,
    subdomain: PropTypes.string.isRequired,
    timeframe: PropTypes.object.isRequired
  };

  state = {
    data: [],
    isLoaded: false
  };

  componentDidMount() {
    this.getData();
  }
  /* called when the site changes or the timeframe for analytics changes */
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ isLoaded: false }, () => this.getData());
    }
  }

  render() {
    return <ProfilesChart {...this.state} nation={this.props.nation} />;
  }

  async getData() {
    const { subdomain, timeframe } = this.props;
    const res = await fetchKeenData({
      subdomain,
      timeframe,
      ...keenProfilesCreated
    });

    const chartData = [
      {
        label: 'Profiles Created',
        color: darkGreen,
        results: res
      }
    ];

    this.setState({
      data: chartData,
      isLoaded: true
    });
  }
}

export const mapStateToProps = ({ profile, sites, stats }) => ({
  nation: sites.items[sites.currentSite].nation,
  subdomain: selectSubdomain(sites, profile),
  timeframe: stats
});

export { ProfileChartContainer as PureProfileChartContainer }; // used in tests

export default connect(mapStateToProps)(ProfileChartContainer);

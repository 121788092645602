import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IframeResizer from 'iframe-resizer-react';
import { generateAlumniWidgetSrcUrl } from '../../../helpers/widget-embed-code-utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Preview = styled.div`
  width: ${({ width }) => width};
  height: 80rem;
  ${({ isHidden }) => isHidden && 'visibility: hidden;'}
`;

const AlumniWidgetBuilderPreview = ({ isHidden, ...props }) => {
  const widgetSrc = generateAlumniWidgetSrcUrl({ ...props });
  const previewWidth = 360;

  return (
    <Wrapper>
      <Preview width={`${previewWidth}px`} isHidden={isHidden}>
        <IframeResizer
          title="Widget Builder Preview"
          heightCalculationMethod="taggedElement"
          checkOrigin="false"
          src={`${widgetSrc}&isPreview=true`}
          style={{ width: '1px', minWidth: '100%' }}
        />
      </Preview>
    </Wrapper>
  );
};

AlumniWidgetBuilderPreview.propTypes = {
  configurations: PropTypes.object.isRequired,
  isHidden: PropTypes.bool
};

export default AlumniWidgetBuilderPreview;

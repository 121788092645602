import { getCognitoToken } from '../';

export const fetchAlumniPathwaysData = async subdomain => {
  const response = await fetch(`/api/cc-api/alumni-pathways/all?subdomain=${subdomain}`, {
    headers: {
      authorization: `Bearer ${await getCognitoToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const {
    data: { attributes }
  } = await response.json();
  return attributes;
};

export const fetchAlumniPathwaysPrograms = async subdomain => {
  const response = await fetch(`/api/cc-api/alumni-pathways/programs?subdomain=${subdomain}`, {
    headers: {
      authorization: `Bearer ${await getCognitoToken()}`,
      'Content-Type': 'application/json'
    }
  });
  const {
    data: {
      attributes: { programs }
    }
  } = await response.json();
  return programs;
};

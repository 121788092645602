import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WidgetBuilderStylesheetHref } from 'containers';
import {
  HorizontalRule,
  WidgetBuilderGeography,
  WidgetBuilderColorPicker,
  ProgramWidgetBuilderPrograms,
  WidgetBuilderToggle,
  WidgetBuilderExportTypeToggle,
  WidgetBuilderSetHeading,
  WidgetBuilderSubdomainAliases,
  WidgetBuilderVisitButtonToggle,
  WidgetBuilderWageToggle
} from 'components';

const Sidebar = styled.div`
  max-width: 41.5rem;
  min-width: 41.5rem;
  height: 67rem;
  padding: 3rem;
  padding-left: 1rem;
  border-right: 2px solid #f0f1f3;
  overflow-y: scroll;
`;

const Header = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`;

const ProgramWidgetBuilderSidebar = props => {
  const { configurations, siteSettings, setConfigurations } = props;
  const { hasCcEnabled, hasCustomLabels, nation, subdomainAliases } = siteSettings;

  const {
    hasCareerList,
    hasEmployment,
    hasImages,
    hasJobOpenings,
    hasOutlook,
    hasSkills,
    labelSet
  } = configurations;

  return (
    <Sidebar>
      <Header>Widget Parameters</Header>
      <WidgetBuilderExportTypeToggle {...props} />
      <HorizontalRule />
      <ProgramWidgetBuilderPrograms {...props} />
      <HorizontalRule />
      <WidgetBuilderSetHeading {...props} />
      <HorizontalRule />
      <WidgetBuilderGeography {...props} />
      <HorizontalRule />
      <WidgetBuilderWageToggle {...props} />
      <HorizontalRule />
      <WidgetBuilderToggle
        label="Career List"
        handleChange={() => setConfigurations({ ...configurations, hasCareerList: !hasCareerList })}
        isChecked={hasCareerList}
      />
      {nation !== 'ca' && (
        <Fragment>
          <HorizontalRule />
          <WidgetBuilderToggle
            label="Job Openings"
            handleChange={() =>
              setConfigurations({ ...configurations, hasJobOpenings: !hasJobOpenings })
            }
            isChecked={hasJobOpenings}
          />
        </Fragment>
      )}

      <HorizontalRule />
      <WidgetBuilderToggle
        label="Current Employment"
        handleChange={() => setConfigurations({ ...configurations, hasEmployment: !hasEmployment })}
        isChecked={hasEmployment}
      />
      <HorizontalRule />
      <WidgetBuilderToggle
        label="Top Skills"
        handleChange={() => setConfigurations({ ...configurations, hasSkills: !hasSkills })}
        isChecked={hasSkills}
      />
      <HorizontalRule />
      <WidgetBuilderToggle
        label="Projected Outlook"
        handleChange={() => setConfigurations({ ...configurations, hasOutlook: !hasOutlook })}
        isChecked={hasOutlook}
      />
      <HorizontalRule />
      <WidgetBuilderToggle
        label="Career Images"
        handleChange={() => setConfigurations({ ...configurations, hasImages: !hasImages })}
        isChecked={hasImages}
      />
      <HorizontalRule />
      {hasCustomLabels && (
        <Fragment>
          <WidgetBuilderToggle
            label="Use Custom Labels"
            handleChange={() => {
              if (labelSet === 'custom') {
                setConfigurations({ ...configurations, labelSet: 'default' });
              } else {
                setConfigurations({ ...configurations, labelSet: 'custom' });
              }
            }}
            isChecked={labelSet === 'custom'}
          />
          <HorizontalRule />
        </Fragment>
      )}
      <WidgetBuilderVisitButtonToggle
        hasCcEnabled={hasCcEnabled}
        configurations={configurations}
        {...props}
      />
      <HorizontalRule />
      <WidgetBuilderColorPicker
        label="Primary Color"
        defaultColor={configurations.primaryColor}
        handleChange={hex => setConfigurations({ ...configurations, primaryColor: hex })}
      />
      <HorizontalRule />
      {!!subdomainAliases && subdomainAliases.length > 0 && (
        <Fragment>
          <WidgetBuilderSubdomainAliases {...props} />
          <HorizontalRule />
        </Fragment>
      )}
      <WidgetBuilderStylesheetHref {...props} />
    </Sidebar>
  );
};

ProgramWidgetBuilderSidebar.propTypes = {
  configurations: PropTypes.shape({
    hasCareerList: PropTypes.bool,
    hasEmployment: PropTypes.bool,
    hasImages: PropTypes.bool,
    hasJobOpenings: PropTypes.bool,
    hasOutlook: PropTypes.bool,
    hasSkills: PropTypes.bool,
    labelSet: PropTypes.string
  }).isRequired,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    nation: PropTypes.string.isRequired,
    subdomainAliases: PropTypes.array
  }).isRequired
};

export default ProgramWidgetBuilderSidebar;

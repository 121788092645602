import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingImage from '../../../images/loading-gray.gif';
import { StickyColumnTable } from 'components';
import { usePagination } from '../../../hooks/usePagination';

const Table = styled(StickyColumnTable)`
  width: unset;
  margin: 2rem;
  border: none;
`;

const EmptyMessage = styled.p`
  width: 100%;
  text-align: center;
  margin: 10rem 2rem;
`;

const Image = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin: auto;
  display: block;
  float: center;
`;

const SkillsByTypeTable = ({ tableData = [], isLoaded, skillType }) => {
  const {
    handlePageNumberChange,
    pageNumber,
    paginatedData: paginatedTableData,
    rowsPerPage
  } = usePagination(tableData);

  const columns = [
    {
      id: 'label',
      header: 'Skill',
      hasSort: false
    },
    {
      id: 'result',
      header: '#',
      hasSort: false,
      textAlign: 'center',
      width: 15
    }
  ];

  if (!isLoaded) {
    let loadingRows = [];
    for (let index = 0; index < 10; index++) {
      loadingRows.push({
        loading: <Image alt="Loading" src={LoadingImage} />
      });
    }
    return (
      <Table
        columns={[
          {
            id: 'loading',
            header: 'Skill',
            hasSort: false
          }
        ]}
        dataCy={`${skillType}-skill-type-table`}
        headingRowColor="white"
        tableMaxWidth="100%"
        isLoading={!isLoaded}
        shouldAlternateRowColor
        tableData={loadingRows}
      />
    );
  }

  if (tableData.length === 0) {
    return (
      <EmptyMessage>{`No skills from ${skillType.replaceAll(
        '-',
        ' '
      )} were added in the selected timeframe.`}</EmptyMessage>
    );
  }

  return (
    <Table
      columns={columns}
      dataCy={`${skillType}-skill-type-table`}
      headingRowColor="white"
      tableMaxWidth="100%"
      isLoading={!isLoaded}
      shouldAlternateRowColor
      tableData={paginatedTableData || []}
      totalRows={tableData.length}
      rowsPerPage={rowsPerPage}
      pageNumber={pageNumber}
      setPageNumber={handlePageNumberChange}
    />
  );
};

SkillsByTypeTable.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  skillType: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      result: PropTypes.number.isRequired
    })
  )
};

export default SkillsByTypeTable;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { verifyToken } from '../../../services/inviteToken';
import { Card, ContentWrap, Loading, SignUpForm } from 'components';

const FormDescription = styled.div`
  margin-block: 1.5rem 3.5rem;
`;

const Wrapper = styled(ContentWrap)`
  margin-top: 8rem;
  max-width: 960px;

  @media (max-width: 549px) {
    h2 {
      text-align: center;
    }
  }
`;

const SignUp = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [decodedToken, setDecodedToken] = useState({});
  const [hasError, setHasError] = useState(false);

  const getToken = async () => {
    const url = new URL(window.location.href).searchParams;
    const token = url.get('token');

    const { email, subdomain, profileId, nation, errors } = await verifyToken({ token });

    setHasError(errors);
    setDecodedToken({
      subdomain,
      profileId,
      nation,
      email
    });
    setIsLoading(false);
  };

  const createAdmin = data => {
    props.createAdmin({
      email: decodedToken.email,
      subdomain: decodedToken.subdomain,
      profileId: decodedToken.profileId,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName
    });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Wrapper>
      <Loading loaded={!isLoading}>
        {hasError ? (
          <Card>
            <FormDescription>
              {hasError} Please reach out to your Account Manager or{' '}
              <a href="mailto:customerservice@lightcast.io">customerservice@lightcast.io</a> to be
              sent a new link.
            </FormDescription>
          </Card>
        ) : (
          <Card>
            <FormDescription>
              To complete your account setup, please fill in the below fields and indicate that you
              have read the privacy policy.
            </FormDescription>
            <SignUpForm nation={decodedToken.nation} createAdmin={createAdmin} />
          </Card>
        )}
      </Loading>
    </Wrapper>
  );
};

SignUp.propTypes = {
  createAdmin: PropTypes.func.isRequired
};

export default SignUp;

import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import { locale } from '../../helpers/locale';
import { withNationHOC } from '../../hocs/withNation';

const { ExcelFile } = ReactExport;

const ExcelStatisticsWrapper = withNationHOC(
  ({
    children,
    filename,
    employerPortal = false,
    hasAssessment = false,
    hasAssessmentWidget = false,
    hasProgramWidget = false,
    hasJobPostings = false,
    hasPrograms = false,
    hasSkillsAssessment = false,
    nation,
    requestEmail = false,
    resumeBuilder = false
  }) => {
    let siteSettingFilters = {
      'Tuition Link Clicks': hasPrograms,
      'Admission Link Clicks': hasPrograms,
      'Top Careers by Job Postings': hasJobPostings
    };

    siteSettingFilters[
      `Top ${locale.spellings.favorite[nation]}d ${locale.edTrack[nation]}s`
    ] = hasPrograms;
    siteSettingFilters[`${locale.resume[nation]}s`] = resumeBuilder;
    siteSettingFilters[`Top Viewed ${locale.edTrack[nation]}s`] = hasPrograms;
    siteSettingFilters[`Top ${locale.edTrack[nation]} Searches`] = hasPrograms;
    siteSettingFilters[`${locale.edTrack[nation]} Link Clicks`] = hasPrograms;
    siteSettingFilters[`Top Info Requests by ${locale.edTrack[nation]}`] = requestEmail;
    siteSettingFilters['Top Employers'] = employerPortal;
    siteSettingFilters['Top Postings'] = employerPortal;

    siteSettingFilters['Interests'] = hasAssessment;

    siteSettingFilters['Total Skills'] = hasSkillsAssessment;
    siteSettingFilters['Skills'] = hasSkillsAssessment;

    siteSettingFilters['AssessmentWidgetUsage'] = hasAssessmentWidget;
    siteSettingFilters['AssessmentWidgetDailyUsage'] = hasAssessmentWidget;
    siteSettingFilters['ProgramsVisitedFromAssessment'] = hasAssessmentWidget;
    siteSettingFilters['CareersVisitedFromAssessment'] = hasAssessmentWidget;

    siteSettingFilters['ProgramWidgetUsage'] = hasProgramWidget;
    siteSettingFilters['ProgramWidgetDailyUsage'] = hasProgramWidget;
    siteSettingFilters['ProgramWidgetTopActivity'] = hasProgramWidget;
    siteSettingFilters['CareersVisitedFromProgramWidget'] = hasProgramWidget;
    siteSettingFilters['Introduction'] = hasAssessmentWidget || hasProgramWidget;

    const nonEmptyChildren = children.filter(
      child =>
        siteSettingFilters[child.props.name] === undefined || siteSettingFilters[child.props.name]
    );

    return (
      <ExcelFile filename={filename} hideElement>
        {nonEmptyChildren}
      </ExcelFile>
    );
  }
);

ExcelStatisticsWrapper.propTypes = {
  filename: PropTypes.string,
  employerPortal: PropTypes.bool,
  hasAssessmentWidget: PropTypes.bool,
  hasJobPostings: PropTypes.bool,
  hasPrograms: PropTypes.bool,
  hasProgramWidget: PropTypes.bool,
  hasSkillsAssessment: PropTypes.bool,
  requestEmail: PropTypes.string,
  resumeBuilder: PropTypes.bool
};

export default ExcelStatisticsWrapper;

export const downloadPdf = async (response, type = 'interest') => {
  const blob = await response.blob();
  const text = await blob.text();
  const href = `data:application/pdf;base64,${text}`;
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = href;
  anchor.download = `${type}_assessment_results.pdf`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  return true;
};
